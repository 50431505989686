import moment from 'moment-timezone';

import getCurrentTimeZone from './getCurrentTimeZone';

export default function convertToLocalTimezone(istDateTime) {
	const istMoment = moment.tz(istDateTime, 'Asia/Kolkata');
	const localMoment = istMoment.clone().tz(getCurrentTimeZone());

	return localMoment;
}
