import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Badge } from 'antd';
import Loader from 'sharedComponents/Loader';
import NotificationList from './components/NotificationList';
import {
	markNotificationsSeen,
	getNotifications,
} from '../../actions/notifications';
import { getCategoryNotifications } from '../../apis/notificationManager';
import styles from './index.module.scss';

import './override.scss';

const { TabPane } = Tabs;

const NotificationView = ({ setIsNotificationsVisible }) => {
	const [isProcessing, setIsProcessing] = useState(false);
	const [currentTab, setCurrentTab] = useState('IMPORTANT');
	const [countOnTabs, setCountOnTabs] = useState({
		all: 0,
		important: 0,
		feedback: 0,
	});

	const notifications = useSelector((s) => s.notification.allNotifications);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!notifications.length) {
			setIsProcessing(true);
			getCategoryNotifications('ALL').then((res) => {
				setIsProcessing(false);
				dispatch(getNotifications(res));
			});
		}
	}, []);

	useEffect(() => {
		const countObj = {
			all: 0,
			important: 0,
			feedback: 0,
		};
		notifications.map((notifItem) => {
			if (!notifItem.seen) {
				notifItem.category.map((category) => {
					switch (category) {
						case 'ALL':
							countObj.all += 1;
							break;
						case 'IMPORTANT':
							countObj.important += 1;
							break;
						case 'FEEDBACK':
							countObj.feedback += 1;
							break;
						default:
							break;
					}
				});
			}
		});
		setCountOnTabs(countObj);
		markSeenForUnactionables(currentTab);
	}, [notifications]);

	const onTabSwitch = (key) => {
		let tabName = '';
		switch (key) {
			case '1':
				tabName = 'ALL';
				break;
			case '2':
				tabName = 'IMPORTANT';
				break;
			case '3':
				tabName = 'FEEDBACK';
				break;
			default:
				tabName = 'IMPORTANT';
		}
		setCurrentTab(tabName);
		markSeenForUnactionables(tabName);
	};

	const markSeenForUnactionables = (tabName) => {
		const idList = [];
		notifications.map((notifItem) => {
			if (
				notifItem.category.indexOf(tabName) >= 0 &&
				!notifItem.action &&
				!notifItem.seen
			) {
				idList.push(notifItem.id);
			}
		});
		if (idList.length) {
			dispatch(markNotificationsSeen(idList));
		}
	};

	const showNotificationList = () => (
		<NotificationList
			currentTab={currentTab}
			setIsNotificationsVisible={setIsNotificationsVisible}
			setCountOnTabs={setCountOnTabs}
			notifications={notifications}
		/>
	);

	return (
		<div className={`${styles.root} navBar`}>
			{isProcessing ? <Loader /> : null}
			<Tabs
				defaultActiveKey="2"
				onChange={onTabSwitch}
				tabBarStyle={{ height: '40px', padding: '0 8px', marginBottom: 0 }}
				style={{ borderBottom: 'none' }}
				centered
			>
				<TabPane
					key="1"
					tab={
						<Badge
							count={countOnTabs.all}
							size="small"
							style={{ backgroundColor: '#4A5568', borderRadius: '4px' }}
						>
							<div className={styles.tabItems}> All </div>
						</Badge>
					}
				>
					{currentTab === 'ALL' ? showNotificationList() : <div></div>}
				</TabPane>
				<TabPane
					key="2"
					className={styles.tabs}
					tab={
						<Badge
							count={countOnTabs.important}
							size="small"
							style={{ backgroundColor: '#4A5568', borderRadius: '4px' }}
						>
							<div className={styles.tabItems}> Important </div>
						</Badge>
					}
				>
					{currentTab === 'IMPORTANT' ? showNotificationList() : <div></div>}
				</TabPane>
				<TabPane
					key="3"
					className={styles.tabs}
					tab={
						<Badge
							count={countOnTabs.feedback}
							size="small"
							style={{ backgroundColor: '#4A5568', borderRadius: '4px' }}
						>
							<div className={styles.tabItems}> Feedback </div>
						</Badge>
					}
				>
					{currentTab === 'FEEDBACK' ? showNotificationList() : <div></div>}
				</TabPane>
			</Tabs>
		</div>
	);
};

NotificationView.propTypes = {
	setIsNotificationsVisible: PropTypes.func,
};

export default NotificationView;
