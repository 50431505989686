import {
	getExpertGroupMappingList,
	getExpertGroupMappingUploadHistory,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setExpertGroupMappingList = createAction(
	'SET_EXPERT_GROUP_MAPPING_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setExpertGroupUploadList = createAction(
	'SET_EXPERT_GROUP_UPLOAD_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setExpertGroupMappingMessages = createAction(
	'SET_EXPERT_GROUP_MAPPING_MESSAGES',
	(data) => ({ payload: data }),
);

export const getExpertGroupList = (query) => (dispatch) => {
	getExpertGroupMappingList(query)
		.then((response) => {
			dispatch(setExpertGroupMappingList(response));
		})
		.catch((err) => {
			dispatch(
				setExpertGroupMappingMessages({ getExpertGroupListError: err.response }),
			);
		});
};

export const getExpertGroupUploadList = (query) => (dispatch) => {
	getExpertGroupMappingUploadHistory(query)
		.then((response) => {
			dispatch(setExpertGroupUploadList(response));
		})
		.catch((err) => {
			dispatch(
				setExpertGroupMappingMessages({ getExpertGroupUploadError: err.response }),
			);
		});
};
