import axios from 'axios';
import { store } from 'store';
import { getCookie, deleteCookie } from 'utils/cookies';
import rollbar from 'utils/rollbar';
import { push } from 'connected-react-router';
import { constants } from 'utils/constants';
import { logoutUserAction } from '../../actions/user';

/*
TODO Write a network layer before axios,
Handle cancellation of network call or ignoring stale response
Camelize all api keys at deep level
*/
const authApi = axios.create({
	baseURL: constants('authApiServerUrl'),
	timeout: 0,
});

authApi.interceptors.request.use((config) => {
	const newConfig = { ...config };
	newConfig.headers[constants('authTokenBackendKey')] = getCookie(
		`upgrad-auth-token.${constants('env')}`,
	);
	newConfig.headers.sessionId = getCookie(
		`upgrad-sessionId.${constants('env')}`,
	);
	return newConfig;
});

authApi.interceptors.response.use(
	(response) => response,
	(error) => {
		const status = error.response?.status;
		if (status === 401) {
			const { data } = error.response;
			const message =
				data.errorList[0]?.errorMessage ||
				'Something went wrong for which you need to contact upGrad support. Thank you for your patience.';
			deleteCookie([
				`upgrad-auth-token.${constants('env')}`,
				`upgrad-sessionId.${constants('env')}`,
			]);
			store.dispatch(logoutUserAction({ errorMessage: message }));
		} else if (status >= 403) {
			store.dispatch(push(`/error/${error.response.status}`));
			rollbar.error(
				`Backend Error [${error.response.status}] with test session API: `,
				error,
			);
		} else if (!status) {
			rollbar.error(`Backend Error [500] with Auth API: `, error);
			store.dispatch(push(`/error/500`));
		}
		return Promise.reject(error);
	},
);

export default authApi;
