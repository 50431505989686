import * as apis from 'apis/auth';
import { generateAuthUrl, storeAuthDetails, getCrmUser } from 'apis/crm';
import { randomString } from 'utils';
import { push } from 'connected-react-router';
import { setCookie, getCookie, deleteCookie } from 'utils/cookies';
import { constants } from 'utils/constants';
import { setUser } from 'utils/clevertap';
import { createAction } from '@reduxjs/toolkit';

export const resetActiveApplication = createAction('RESET_ACTIVE_APPLICATION');

export const setUserData = createAction('SET_USER_DETAILS', (data) => ({
	payload: {
		email: data.email,
		roles: data.roles,
		firstName: data.firstname,
		name: data.name,
		phoneNumber: data.phoneNumber,
		lastName: data.lastname,
		id: data.id,
		doselect: data.doSelectProperties,
		profilePic: data.profilePic,
		rolesStringSet: data.rolesStringSet,
	},
}));

export const logoutUserAction = createAction('LOGOUT_USER', (errorMessage) => ({
	payload: { errorMessage },
}));

export const setGoogleAuthUrl = createAction('SET_GOOGLE_AUTH_URL', (data) => ({
	payload: data,
}));

export const setCrmUserDetails = createAction(
	'SET_CRM_USER_DETAILS',
	(data) => ({
		payload: data,
	}),
);

export function loginUser({ email, password }) {
	return (dispatch) => {
		const ssid = randomString(32);
		setCookie({ [`upgrad-sessionId.${constants('env')}`]: ssid });
		return apis
			.loginUser({ email, password })
			.then((response) => {
				const { data } = response;
				const allowedUser = true;
				if (data.id && allowedUser) {
					const cookieData = {};
					const authCookieKey = `upgrad-auth-token.${constants('env')}`;
					cookieData[authCookieKey] = response.headers['auth-token'];
					setCookie(cookieData);
					setUser(data);
					dispatch(setUserData(data));
				} else {
					throw new Error('Permission Denied');
				}
			})
			.catch((err) => {
				deleteCookie([`upgrad-sessionId.${constants('env')}`]);
				return Promise.reject(err);
			});
	};
}

export function authenticateUser() {
	return (dispatch) =>
		apis
			.authenticateUser()
			.then((response) => {
				const allowedUser = true;
				if (allowedUser) {
					dispatch(setUserData(response.user));
				} else {
					dispatch(push(`/error/401`));
				}
			})
			.catch((err) => Promise.reject(err));
}

export function getUserDetails() {
	return (dispatch) =>
		apis
			.getUserDetails()
			.then((response) => {
				const { data } = response;
				const allowedUser = true;
				if (allowedUser) {
					dispatch(setUserData(data));
				} else {
					dispatch(push(`/error/401`));
				}
			})
			.catch(() => {
				dispatch(push(`/error/500`));
			});
}

export function logoutUser(errorMessage = '') {
	return (dispatch) => {
		if (getCookie(`upgrad-auth-token.${constants('env')}`)) {
			apis
				.logoutUser()
				.then(() => {
					ejectUser(dispatch, errorMessage);
				})
				.catch(() => {
					ejectUser(dispatch, errorMessage);
				});
		} else {
			ejectUser(dispatch, errorMessage);
		}
	};
}

const ejectUser = (dispatch, em) => {
	deleteCookie([
		`upgrad-auth-token.${constants('env')}`,
		`upgrad-sessionId.${constants('env')}`,
		`activeApplicationId`,
	]);
	dispatch(logoutUserAction(em));
	dispatch(resetActiveApplication());
	dispatch(push(`/login`));
	return true;
};

export function resetUser() {
	return (dispatch) => {
		dispatch(logoutUserAction());
	};
}

export const changeLanguage =
	(locale) =>
	// dynamically load language JSON locale file names should be ISO refer https://www.w3schools.com/tags/ref_language_codes.asp
	(dispatch) => {
		import(`../locale/${locale}.json`)
			.then(({ default: language }) => {
				document.getElementsByTagName('html')[0].setAttribute('lang', locale);
				dispatch({
					type: 'SET_LANGUAGE',
					language,
					locale,
				});
			})
			.catch(() => {});
	};

// Google calendar permissions
export const generateGoogleAuthUrl = () => (dispatch) => {
	generateAuthUrl()
		.then((response) => {
			dispatch(setGoogleAuthUrl(response.data));
		})
		.catch((err) => Promise.reject(err));
};

export const storeGoogleAuthDetails = (code) => () => {
	storeAuthDetails(code).catch((err) => Promise.reject(err));
};

// CRM user details
export const getCrmUserDetails = (email) => (dispatch) => {
	getCrmUser(email)
		.then((response) => dispatch(setCrmUserDetails(response.data.data || {})))
		.catch((err) => Promise.reject(err));
};
