/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setAlumniList,
	setAlumniMessages,
	setCurrentDomains,
} from '../actions/alumni';

const initialState = {
	alumni: { data: [], headers: {} },
	domains: [],
	messages: {
		alumniUploadError: {},
		getAlumniDataError: {},
		getDomainError: {},
	},
};

const alumniReducer = createReducer(initialState, {
	[setAlumniList]: (state, action) => {
		state.alumni = action.payload;
	},
	[setCurrentDomains]: (state, action) => {
		state.domains = action.payload;
	},
	[setAlumniMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default alumniReducer;
