export const constants = (key) => process.env[`REACT_APP_${key}`];

export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const ALUMNI_CONNECT_EVENT_TYPE = 'ALUMNI_CONNECT';

export const MEETING_WINDOW_STATUS = {
	CREATED: 'CREATED',
	OPEN: 'OPEN',
	INPROGRESS: 'INPROGRESS',
	CLOSED: 'CLOSED',
	EXTENDED_OPEN: 'EXTENDED_OPEN',
	EXPIRED: 'EXPIRED',
};

export const ZOOM_MEETING_CHANNEL = 'ZOOM';

export const MEETING_CHANNEL = {
	ZOOM: 'Zoom',
	UGL: 'upGrad Live',
};
// Duration is in minutes
export const UPLOAD_RECORDING_BUTTON_ENABLED_DURATION = {
	EXPERT: 2880,
	ADMIN: 43200,
};

export const AVAILABILITY_END_MONTH = 5;
export const AVAILABILITY_LIMIT_IN_DAYS_FOR_ALUMNI = 7;
export const AVAILABILITY_DISABLED_HOURS = Object.freeze([0, 1, 2, 3, 4, 5, 6]);

export const timezone = 'Asia/Kolkata';
