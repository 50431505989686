import {
	getLearnerGroupMappingList,
	getLearnerGroupMappingUploadHistory,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setLearnerGroupMappingList = createAction(
	'SET_LEARNER_GROUP_MAPPING_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setLearnerGroupUploadList = createAction(
	'SET_LEARNER_GROUP_UPLOAD_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setLearnerGroupMappingMessages = createAction(
	'SET_LEARNER_GROUP_MAPPING_MESSAGES',
	(data) => ({ payload: data }),
);

export const getLearnerGroupList = (query) => (dispatch) => {
	getLearnerGroupMappingList(query)
		.then((response) => {
			dispatch(setLearnerGroupMappingList(response));
		})
		.catch((err) => {
			dispatch(
				setLearnerGroupMappingMessages({ getLearnerGroupListError: err.response }),
			);
		});
};

export const getLearnerGroupUploadList = (query) => (dispatch) => {
	getLearnerGroupMappingUploadHistory(query)
		.then((response) => {
			dispatch(setLearnerGroupUploadList(response));
		})
		.catch((err) => {
			dispatch(
				setLearnerGroupMappingMessages({
					getLearnerGroupUploadError: err.response,
				}),
			);
		});
};
