import React from 'react';

const LinkSquare = (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0 4.8C0 2.14903 2.14903 0 4.8 0H12C14.651 0 16.8 2.14903 16.8 4.8V12C16.8 14.651 14.651 16.8 12 16.8V14.4C13.3255 14.4 14.4 13.3255 14.4 12V4.8C14.4 3.47453 13.3255 2.4 12 2.4H4.8C3.47453 2.4 2.4 3.47453 2.4 4.8V12C2.4 13.3255 3.47453 14.4 4.8 14.4V16.8C2.14903 16.8 0 14.651 0 12V4.8Z"
			fill="#4F8AFB"
		/>
		<path
			d="M7.19995 19.2C7.19995 21.851 9.34898 24 12 24H19.2C21.851 24 24 21.851 24 19.2V12C24 9.34898 21.851 7.19995 19.2 7.19995V9.59995C20.5254 9.59995 21.6 10.6745 21.6 12V19.2C21.6 20.5254 20.5254 21.6 19.2 21.6H12C10.6745 21.6 9.59995 20.5254 9.59995 19.2V12C9.59995 10.6745 10.6745 9.59995 12 9.59995V7.19995C9.34898 7.19995 7.19995 9.34898 7.19995 12V19.2Z"
			fill="#4F8AFB"
		/>
	</svg>
);

export default LinkSquare;
