/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setExpertMappingList,
	setExpertUploadList,
	setIndustryDomains,
	setCoreSkills,
	setExpertMappingMessages,
} from '../actions/expertMapping';

const initialState = {
	allExperts: { data: [], headers: {} },
	expertUploads: { data: [], headers: {} },
	industryDomains: [],
	coreSkills: [],
	messages: {
		getExpertListError: {},
		getExpertUploadError: {},
		getExpertListDownloadError: {},
		getIndustryDomainsError: {},
		getCoreSkillsError: {},
	},
};

const expertMappingReducer = createReducer(initialState, {
	[setExpertMappingList]: (state, action) => {
		state.allExperts = action.payload;
	},
	[setExpertUploadList]: (state, action) => {
		state.expertUploads = action.payload;
	},
	[setIndustryDomains]: (state, action) => {
		state.industryDomains = action.payload;
	},
	[setCoreSkills]: (state, action) => {
		state.coreSkills = action.payload;
	},
	[setExpertMappingMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default expertMappingReducer;
