import {
	bulkUploadUsers,
	getUserList,
	getUserUploadHistory,
	getUser,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setUserList = createAction('SET_USER_LIST', (data) => ({
	payload: data,
}));

export const setUserProfile = createAction('SET_USER_PROFILE', (data) => ({
	payload: data,
}));

export const setConsumerMessages = createAction(
	'SET_CONSUMER_MESSAGES',
	(data) => ({ payload: data }),
);

export const uploadUsers = (payload) => (dispatch) => {
	bulkUploadUsers(payload).catch((err) => {
		dispatch(setConsumerMessages({ uploadError: err.response }));
	});
};

export const getUploadHistory = (query) => (dispatch) => {
	getUserUploadHistory(query).catch((err) => {
		dispatch(setConsumerMessages({ getHistoryError: err.response }));
	});
};

export const getUsers = (query) => (dispatch) => {
	getUserList(query)
		.then((response) => {
			dispatch(setUserList(response));
		})
		.catch((err) => {
			dispatch(setConsumerMessages({ getUsersError: err.response }));
		});
};

export const getUserProfile = (id) => (dispatch) => {
	getUser(id)
		.then((response) => {
			dispatch(setUserProfile(response.data));
		})
		.catch((err) => {
			dispatch(setConsumerMessages({ getUserProfileError: err.response }));
		});
};
