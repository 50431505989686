import React from 'react';
const DeviceMeetingRoom = (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 29 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.51479 1C5.51379 1 3.77911 2.38468 3.33564 4.33591L0.608381 16.3358C-0.00113326 19.0178 2.03729 21.5714 4.78752 21.5714H23.6257C26.3759 21.5714 28.4142 19.0178 27.8048 16.3358L25.0775 4.33591C24.6341 2.38468 22.8994 1 20.8983 1H7.51479ZM5.00731 4.71583C5.27338 3.5451 6.31419 2.71429 7.51479 2.71429H20.8983C22.099 2.71429 23.1397 3.5451 23.4058 4.71583L26.1332 16.7159C26.4989 18.3249 25.2757 19.8571 23.6257 19.8571H4.78752C3.13738 19.8571 1.91432 18.3249 2.28003 16.7159L5.00731 4.71583Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.5"
		/>
		<path
			d="M6.49246 23.2856C6.01907 23.2856 5.63531 23.6695 5.63531 24.1428C5.63531 24.6161 6.01907 24.9999 6.49246 24.9999H21.921C22.3943 24.9999 22.7782 24.6161 22.7782 24.1428C22.7782 23.6695 22.3943 23.2856 21.921 23.2856H6.49246Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.5"
		/>
	</svg>
);

export default DeviceMeetingRoom;
