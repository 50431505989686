import { getFormDetails } from 'apis/formBuilder';
import { createAction } from '@reduxjs/toolkit';
import { getAllForms } from 'apis/crm';

export const setFormList = createAction('SET_FORM_LIST', (data) => ({
	payload: data,
}));

export const setFormDetails = createAction('SET_FORM_DETAILS', (data) => ({
	payload: data,
}));

export const setFeedbackFormMessages = createAction(
	'SET_FEEDBACK_FORM_MESSAGES',
	(data) => ({ payload: data }),
);

export const getFeedbackFormList = (query) => (dispatch) => {
	getAllForms(query)
		.then((response) => {
			dispatch(setFormList(response));
		})
		.catch((err) => {
			dispatch(setFeedbackFormMessages({ getFormListError: err.response }));
		});
};

export const getFeedbackFormDetails = (id) => (dispatch) => {
	getFormDetails(id)
		.then((response) => {
			dispatch(setFormDetails(response.data));
		})
		.catch((err) => {
			dispatch(setFeedbackFormMessages({ getFormDetailsError: err.response }));
		});
};
