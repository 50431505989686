/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setFeedbackMessages,
	setFeedbackSubmittedInfoList,
	setLearnerFeedback,
	setFeedbackListEmpty,
	setFeedbackSubmittedInfoEmpty,
	setLoader,
} from '../actions/feedback';

const initialState = {
	feedbackList: {},
	feedbackSubmittedInfo: {},
	loader: true,
	messages: {
		feedbackListError: {},
		feedbackSubmittedInfoError: {},
	},
};

const feedbackReducer = createReducer(initialState, {
	[setLearnerFeedback]: (state, action) => {
		state.feedbackList = { ...state.feedbackList, ...action.payload };
	},
	[setFeedbackListEmpty]: (state) => {
		state.feedbackList = [];
	},
	[setFeedbackSubmittedInfoList]: (state, action) => {
		state.feedbackSubmittedInfo = action.payload;
	},
	[setFeedbackSubmittedInfoEmpty]: (state) => {
		state.feedbackSubmittedInfo = {};
	},
	[setFeedbackMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
	[setLoader]: (state, action) => {
		state.loader = action.payload;
	},
});

export default feedbackReducer;
