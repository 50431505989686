/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	logoutUserAction,
	setUserData,
	setGoogleAuthUrl,
	setCrmUserDetails,
} from '../actions/user';

const initialState = {
	email: '',
	name: '',
	firstName: '',
	lastName: '',
	phoneNumber: '',
	roles: [],
	id: null,
	errorMessage: '',
	doselect: {},
	profilePic: '',
	rolesStringSet: [],
	googleAuthUrl: '',
	crmId: null,
	calenderPermission: false,
};

const userReducer = createReducer(initialState, {
	[logoutUserAction]: (state, action) => {
		state = {
			...state,
			...action.payload,
		};
	},
	[setUserData]: (state, action) => {
		state.email = action.payload.email;
		state.name = action.payload.name;
		state.firstName = action.payload.firstName;
		state.lastName = action.payload.lastName;
		state.phoneNumber = action.payload.phoneNumber;
		state.roles = action.payload.rolesStringSet
			? action.payload.roles
			: action.payload.roleGroups;
		state.id = action.payload.id;
		state.doselect = action.payload.doselect;
		state.profilePic = action.payload.profilePic;
		state.rolesStringSet = action.payload.rolesStringSet || action.payload.roles;
	},
	[setGoogleAuthUrl]: (state, action) => {
		state.googleAuthUrl = action.payload;
	},
	[setCrmUserDetails]: (state, action) => {
		state.crmId = action.payload.consumerId;
		state.calenderPermission = action.payload.calenderPermission;
		state;
	},
});
export default userReducer;
