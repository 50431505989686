/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setLearnerGroupMappingList,
	setLearnerGroupUploadList,
	setLearnerGroupMappingMessages,
} from '../actions/learnerGroupMapping';

const initialState = {
	allLearnerGroups: { data: [], headers: {} },
	learnerGroupUploads: { data: [], headers: {} },
	messages: {
		getLearnerGroupListError: {},
		getLearnerGroupUploadError: {},
	},
};

const learnerGroupMappingReducer = createReducer(initialState, {
	[setLearnerGroupMappingList]: (state, action) => {
		state.allLearnerGroups = action.payload;
	},
	[setLearnerGroupUploadList]: (state, action) => {
		state.learnerGroupUploads = action.payload;
	},
	[setLearnerGroupMappingMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default learnerGroupMappingReducer;
