import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { crmNavs, userNavs } from './constants/navOptions';
import { allowedFeature } from '../../accessControl';
import TimezoneSelect from '../../modules/crm/TimezoneSelection';
import styles from './index.module.scss';
const { Header } = Layout;

const SecondaryHeader = ({ location, isMobile }) => {
	const userInfo = useSelector((s) => s.user);
	const [navs, setNavs] = useState([]);

	useEffect(() => {
		const path = location.pathname.split('/')[1];
		switch (path) {
			case 'crm':
				setNavs(crmNavs);
				break;
			case 'users':
				setNavs(userNavs);
				break;
			default:
				setNavs([]);
		}
	}, [location]);

	return (
		<>
			{navs.length > 0 ? (
				<Header className={styles.root}>
					<Menu
						mode="horizontal"
						size="small"
						forceSubMenuRender
						selectedKeys={[location.pathname.split('/')[2]]}
						style={{
							width: isMobile ? '1000px' : '100%',
							textAlign: isMobile ? 'left' : 'center',
							borderColor: 'rgb(224, 227, 237)',
						}}
					>
						{navs.map((obj) => {
							if (
								obj.features &&
								allowedFeature(obj.features, userInfo.rolesStringSet)
							) {
								return (
									<Menu.Item key={obj.key}>
										<NavLink to={obj.path}>{obj.name}</NavLink>
									</Menu.Item>
								);
							}
							return null;
						})}
					</Menu>
					<TimezoneSelect />
				</Header>
			) : null}
		</>
	);
};

SecondaryHeader.propTypes = {
	location: PropTypes.object,
	isMobile: PropTypes.bool,
};

export default SecondaryHeader;
