import {
	getNotificationUnreadCount,
	setNotificationsSeen,
} from 'apis/notificationManager';
import { createAction } from '@reduxjs/toolkit';

export const setNotificationList = createAction(
	'SET_NOTIFICATION_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setRealTimeNotification = createAction(
	'SET_REAL_TIME_NOTIFICATION',
	(data) => ({
		payload: data,
	}),
);

export const setUnreadNotificationCount = createAction(
	'SET_UNREAD_NOTIFICATION_COUNT',
	(data) => ({
		payload: data,
	}),
);

export const updateNotificationsStatus = createAction(
	'SET_NOTIFICATION_SEEN',
	(data) => ({
		payload: data,
	}),
);

export const setNotificationMessages = createAction(
	'SET_NOTIFICATION_MESSAGES',
	(data) => ({
		payload: data,
	}),
);

export const getNotifications = (data) => (dispatch) => {
	dispatch(setNotificationList(data));
};

export const getUnreadNotificationCount = () => (dispatch) => {
	getNotificationUnreadCount()
		.then((response) => {
			dispatch(setUnreadNotificationCount(response.count));
		})
		.catch((err) => {
			dispatch(
				setNotificationMessages({ getNotificationCountError: err.response }),
			);
		});
};

export const markNotificationsSeen = (list) => (dispatch) => {
	setNotificationsSeen(list)
		.then(() => {
			dispatch(getUnreadNotificationCount());
			dispatch(updateNotificationsStatus(list));
		})
		.catch((err) => {
			dispatch(
				setNotificationMessages({ markNotificationsSeenError: err.response }),
			);
		});
};

export const addRealTimeNotification = (data) => (dispatch) => {
	dispatch(setRealTimeNotification(data));
};
