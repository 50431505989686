/* eslint-disable */
import { createReducer, createAction } from '@reduxjs/toolkit';
import { nanoid } from '@reduxjs/toolkit';
import { constants } from 'utils/constants';
import en from '../locale/en.json';

const initialState = {
	loader: 0,
	offline: false,
	locale: document.getElementsByTagName('html')[0].getAttribute('lang'),
	language: en,
	notifications: {},
	isMobile: false,
};

export const setLang = createAction('SET_LANGUAGE');
export const loaderOn = createAction('LOADER_ON');
export const loaderOff = createAction('LOADER_OFF');
export const setOffline = createAction('SET_OFFLINE');
export const sendNotification = createAction('SEND_NOTIFICATION');
export const closeNotification = createAction('CLOSE_NOTIFICATION');
export const setMobile = createAction('SET_IS_MOBILE');

const globalReducer = createReducer(initialState, {
	[setLang]: (state, action) => {
		state.locale = action.payload.locale;
		state.language = action.payload.language;
	},
	// to handle multple loader calls
	[loaderOn]: (state) => {
		state.loader += 1;
	},
	[loaderOff]: (state) => {
		state.loader = state.loader > 0 ? state.loader - 1 : 0;
	},
	[setOffline]: (s, a) => {
		s.offline = a.payload.status;
	},
	[sendNotification]: (s, a) => {
		s.notifications[nanoid()] = a.payload;
	},
	[setMobile]: (s, a) => {
		const breakpoint = constants('desktopBreakPoint');
		s.isMobile = a.payload < parseInt(breakpoint, 10);
	},
	[closeNotification]: (s, a) => {
		if (a.index >= 0) {
			delete s.notifications[a.index];
		} else {
			delete s.notifications[Object.keys(s.notifications)[0]];
		}
	},
});
export default globalReducer;
