import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CircularSpinner from '../CircularSpinner/index';
import styles from './index.module.scss';

const Button = React.forwardRef(
	(
		{
			className,
			size,
			variant,
			noShadow,
			disabled,
			isLoading,
			type,
			onClick,
			label,
			children,
			style,
			width,
			height,
			id,
			loaderStrokeColor,
		},
		ref,
	) => {
		const handleButtonClick = (event) => {
			if (disabled || !onClick) return;
			/*
            Do something before click event is passed to business logic
            like tracking for analytics
        */
			onClick({
				event,
			});
		};

		const renderChildren = () => {
			if (label) return label;
			return children;
		};

		const classNames = cx(className, size, variant, styles.button, {
			[styles.loading]: isLoading,
			[styles.noShadow]: noShadow,
			[styles.disabled]: disabled,
			[styles.disabledClassName]: disabled,
			[styles.size]: size,
		});

		let genStyle = {};
		if (width) {
			genStyle.width = `${width}px`;
		}

		if (height) {
			genStyle.height = `${height}px`;
		}

		genStyle = { ...genStyle, ...style };

		return (
			<button
				style={genStyle}
				ref={ref}
				type={type}
				onClick={handleButtonClick}
				className={classNames}
				id={id}
			>
				{isLoading && (
					<span className="loading">
						<CircularSpinner strokeColor={loaderStrokeColor} />
					</span>
				)}
				{renderChildren()}
			</button>
		);
	},
);

export default Button;

Button.propTypes = {
	onClick: PropTypes.func,
	children: PropTypes.node,
	variant: PropTypes.string,
	className: PropTypes.string,
	label: PropTypes.string,
	size: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	disabled: PropTypes.bool,
	noShadow: PropTypes.bool,
	isLoading: PropTypes.bool,
	type: PropTypes.string,
	id: PropTypes.string,
	style: PropTypes.object,
	loaderStrokeColor: PropTypes.string,
};

Button.defaultProps = {
	className: '',
	label: '',
	size: '',
	variant: 'primary',
	disabled: false,
	noShadow: false,
	isLoading: false,
	loaderStrokeColor: 'none',
};
