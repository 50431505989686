import { roles } from './roles';
import { features } from './features';
import { modules } from './modules';

// feature to role access mapping - min access required
export const accessMap = {
	[modules.DASHBOARD]: [
		roles.ADMIN,
		roles.PA,
		roles.EXPERT,
		roles.ALUMNI,
		roles.ALUMNI_OPS,
	],
	[modules.HOME]: [
		roles.ADMIN,
		roles.PA,
		roles.EXPERT,
		roles.ALUMNI,
		roles.ALUMNI_OPS,
	],
	[modules.CRM]: [
		roles.ADMIN,
		roles.PA,
		roles.EXPERT,
		roles.ALUMNI_OPS,
		roles.ALUMNI,
	],
	[modules.USERS]: [roles.ADMIN, roles.PA, roles.EXPERT, roles.ALUMNI_OPS],
	[modules.PROFILE]: [
		roles.ADMIN,
		roles.PA,
		roles.EXPERT,
		roles.ALUMNI,
		roles.ALUMNI_OPS,
	],

	// Availability Management
	[features.AVAILABILITY_LIST_VIEW]: [
		roles.ADMIN,
		roles.PA,
		roles.EXPERT,
		roles.ALUMNI,
		roles.ALUMNI_OPS,
	],

	// Calendar Management
	[features.CALENDAR_EVENT_LIST_VIEW]: [
		roles.ADMIN,
		roles.PA,
		roles.EXPERT,
		roles.ALUMNI,
		roles.ALUMNI_OPS,
	],
	[features.CALENDAR_EVENT_CALENDAR_VIEW]: [roles.EXPERT],
	[features.ADD_CALENDAR_EVENT]: [roles.ADMIN, roles.PA],

	// Meeting Management
	[features.MEETING_EVENT_LIST_VIEW]: [roles.ADMIN, roles.PA],

	// Mapping Management
	[features.EXPERT_MANAGEMENT_VIEW]: [roles.ADMIN, roles.PA, roles.ALUMNI_OPS],
	[features.LEARNER_MANAGEMENT_VIEW]: [roles.ADMIN, roles.PA, roles.COACH],
	[features.GROUP_MANAGEMENT_VIEW]: [roles.ADMIN, roles.PA, roles.COACH],
	[features.EXPERT_GROUP_MANAGEMENT_VIEW]: [roles.ADMIN, roles.PA],
	[features.LEARNER_GROUP_MANAGEMENT_VIEW]: [roles.ADMIN, roles.PA, roles.COACH],

	// Feedback Form Management
	[features.FEEDBACK_FORM_LIST_VIEW]: [roles.ADMIN, roles.PA],

	// Communication Management
	[features.FEEDBACK_REMINDER_VIEW]: [roles.ADMIN, roles.PA, roles.ALUMNI_OPS],
	[features.AVAILABILITY_REMINDER_VIEW]: [
		roles.ADMIN,
		roles.PA,
		roles.ALUMNI_OPS,
	],
	[features.MEETING_REMINDER_VIEW]: [roles.ADMIN, roles.PA, roles.ALUMNI_OPS],

	// Request Management
	[features.REQUEST_LIST_VIEW]: [roles.ADMIN, roles.PA, roles.ALUMNI_OPS],

	// Category Management
	[features.CATEGORY_LIST_VIEW]: [roles.ADMIN],

	// User Management
	[features.USER_LIST_VIEW]: [roles.ADMIN, roles.PA, roles.ALUMNI_OPS],
	[features.BULK_ADD_USER]: [roles.ADMIN, roles.PA, roles.ALUMNI_OPS],

	// Mentorship and Carrier Coaching
	[features.MEETING_WINDOW]: [roles.ADMIN, roles.PA, roles.EXPERT],
	[features.MEETING_WINDOW_EDIT_UPDATE_ACCESS]: [roles.ADMIN, roles.PA],

	// student list
	[features.STUDENT_LIST_VIEW]: [roles.ADMIN, roles.PA, roles.EXPERT],
};
