const checkRoleOfUser = (userRoles, roles) => {
	let flag = false;
	roles.forEach((role) => {
		if (userRoles.includes(role)) {
			flag = true;
		}
	});
	return flag;
};

export default checkRoleOfUser;
