/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setMeetingWindowList,
	setLearnerList,
	setMeetingWindowMessages,
	setDates,
} from '../actions/meetingWindow';

const initialState = {
	slotsData: { data: [], headers: {} },
	learnerList: { data: [], headers: {} },
	dates: {},
	messages: {
		getMeetingWindowListError: {},
		getLearnerListError: {},
		deleteSuccess: {},
		getDateError: {},
		deleteError: {},
	},
};

const meetingWindowReducer = createReducer(initialState, {
	[setMeetingWindowList]: (state, action) => {
		state.slotsData = action.payload;
	},
	[setLearnerList]: (state, action) => {
		state.learnerList = action.payload;
	},
	[setDates]: (state, action) => {
		state.dates = action.payload;
	},
	[setMeetingWindowMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default meetingWindowReducer;
