import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import Loader from 'sharedComponents/Loader';
import { redirect } from '../../../../actions/navigation';
import { markNotificationsSeen } from '../../../../actions/notifications';
import { setCalendarEventPosition } from '../../../../actions/calendarEvents';
import { getMeetingPosition } from '../../../../apis/crm';
import styles from './index.module.scss';

const NotificationItem = ({ setIsNotificationsVisible, notificationItem }) => {
	const dispatch = useDispatch();

	const [isProcessing, setIsProcessing] = useState(false);

	const handleCtaClick = (id) => {
		if (notificationItem.params?.id && notificationItem.params.id !== 'null') {
			setIsProcessing(true);
			getMeetingPosition(`?meetingId=${notificationItem.params.id}`)
				.then((res) => {
					setIsProcessing(false);
					if (res.data.meetingStatus !== 'CANCELLED') {
						dispatch(setCalendarEventPosition(res.data));
					}
					setIsNotificationsVisible(false);
					dispatch(redirect(notificationItem.action.destination));
					dispatch(markNotificationsSeen([id]));
				})
				.catch(() => setIsProcessing(false));
		} else {
			setIsNotificationsVisible(false);
			dispatch(redirect(notificationItem.action.destination));
			dispatch(markNotificationsSeen([id]));
		}
	};

	const getBodyText = (bodyText, initTime, params) => {
		let newText = bodyText;
		if (initTime) {
			newText = newText.replace(
				'<date_time>',
				moment(initTime).format('YYYY-MM-DD HH:mm'),
			);
		}
		if (params && params.newStartTimeUtc) {
			newText = newText.replace(
				'<new_date_time>',
				moment(params.newStartTimeUtc).format('YYYY-MM-DD HH:mm'),
			);
		}
		return newText;
	};

	return (
		<div className={cx(styles.root, { [styles.read]: notificationItem.seen })}>
			{isProcessing ? <Loader /> : null}
			<div className={styles.leftPart}>
				<img
					className={styles.iconHolder}
					src={notificationItem.imageUrl}
					alt="notification"
				/>
			</div>
			<div className={styles.rightPart}>
				<div className={styles.titleBar}>
					<div className={styles.title}> {notificationItem.title} </div>
					<div className={styles.time}>
						{moment(notificationItem.created).format('HH:mm')}
					</div>
				</div>
				<div className={styles.description}>
					{getBodyText(
						notificationItem.body,
						notificationItem.initTime,
						notificationItem.params,
					)}
				</div>
				{notificationItem.action ? (
					<div
						className={styles.cta}
						onClick={() => handleCtaClick(notificationItem.id)}
						role="button"
					>
						{notificationItem.action.label}
					</div>
				) : null}
			</div>
		</div>
	);
};

NotificationItem.propTypes = {
	setIsNotificationsVisible: PropTypes.func,
	notificationItem: PropTypes.object,
};

export default NotificationItem;
