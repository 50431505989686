import {
	getMeetingWindow,
	getMeetingWindowLearner,
	deleteWindow,
	getWindowEditDetails,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setMeetingWindowList = createAction(
	'SET_MEEETING_WINDOW_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setLearnerList = createAction('SET_LEARNER_LIST', (data) => ({
	payload: data,
}));

export const setDates = createAction('SET_DATES', (data) => ({
	payload: data,
}));

export const setMeetingWindowMessages = createAction(
	'SET_MEETING_WINDOW_MESSAGES',
	(data) => ({ payload: data }),
);

export const getMeetingWindowList = (query) => (dispatch) => {
	getMeetingWindow(query)
		.then((response) => {
			dispatch(setMeetingWindowList(response));
		})
		.catch((err) => {
			dispatch(
				setMeetingWindowMessages({ getMeetingWindowListError: err.response }),
			);
		});
};

export const getMaxMinDates = (query) => (dispatch) => {
	getWindowEditDetails(query)
		.then((response) => {
			dispatch(setDates(response.data));
		})
		.catch((err) => {
			dispatch(setMeetingWindowMessages({ getDateError: err.response }));
		});
};

export const getLearnerList = (id, query) => (dispatch) => {
	getMeetingWindowLearner(id, query)
		.then((response) => {
			dispatch(setLearnerList(response));
		})
		.catch((err) => {
			dispatch(setMeetingWindowMessages({ getLearnerListError: err.response }));
		});
};

export const deleteMeetingWindow = (id) => (dispatch) => {
	deleteWindow(id)
		.then((response) => {
			dispatch(
				setMeetingWindowMessages({
					deleteSuccess: response.data,
					recordId: id,
				}),
			);
		})
		.catch((err) => {
			dispatch(setMeetingWindowMessages({ deleteError: err.response }));
		});
};
