/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setGroupMappingList,
	setProgramNames,
	setGroupUploadList,
	setGroupDetails,
	setGroupMappingMessages,
	setApplicableGroupDetails,
} from '../actions/groupMapping';

const initialState = {
	allGroups: { data: [], headers: {} },
	programs: [],
	groupUploads: { data: [], headers: {} },
	groupDetails: {},
	applicableGroups: [],
	messages: {
		getGroupListError: {},
		getGroupUploadError: {},
		getGroupDetailError: {},
		getProgramListError: {},
		getApplicableGroupDetailError: {},
	},
};

const groupMappingReducer = createReducer(initialState, {
	[setGroupMappingList]: (state, action) => {
		state.allGroups = action.payload;
	},
	[setProgramNames]: (state, action) => {
		state.programs = action.payload;
	},
	[setGroupUploadList]: (state, action) => {
		state.groupUploads = action.payload;
	},
	[setGroupDetails]: (state, action) => {
		state.groupDetails = action.payload;
	},
	[setApplicableGroupDetails]: (state, action) => {
		state.applicableGroups = action.payload;
	},
	[setGroupMappingMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default groupMappingReducer;
