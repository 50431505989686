const getFullName = (fName, lName) => {
	let nameText = '';
	if (fName) {
		nameText += `${fName} `;
	}
	if (lName) {
		nameText += lName;
	}
	return nameText;
};

export default getFullName;
