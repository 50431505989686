/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setExpertGroupMappingList,
	setExpertGroupUploadList,
	setExpertGroupMappingMessages,
} from '../actions/expertGroupMapping';

const initialState = {
	allExpertGroups: { data: [], headers: {} },
	expertGroupUploads: { data: [], headers: {} },
	messages: {
		getExpertGroupListError: {},
		getExpertGroupUploadError: {},
	},
};

const expertGroupMappingReducer = createReducer(initialState, {
	[setExpertGroupMappingList]: (state, action) => {
		state.allExpertGroups = action.payload;
	},
	[setExpertGroupUploadList]: (state, action) => {
		state.expertGroupUploads = action.payload;
	},
	[setExpertGroupMappingMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default expertGroupMappingReducer;
