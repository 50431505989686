/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setLearnerMappingList,
	setLearnerUploadList,
	setLearnerMappingMessages,
} from '../actions/learnerMapping';

const initialState = {
	allLearners: { data: [], headers: {} },
	learnerUploads: { data: [], headers: {} },
	messages: {
		getLearnerListError: {},
		getLearnerUploadError: {},
	},
};

const learnerMappingReducer = createReducer(initialState, {
	[setLearnerMappingList]: (state, action) => {
		state.allLearners = action.payload;
	},
	[setLearnerUploadList]: (state, action) => {
		state.learnerUploads = action.payload;
	},
	[setLearnerMappingMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default learnerMappingReducer;
