import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Button } from 'antd';
import { constants } from 'utils/constants';
import styles from './index.module.scss';
const { Footer } = Layout;

const SiteFooter = ({ isMobile }) => (
	<Footer className={styles.root}>
		{!isMobile && (
			<ul className={styles.firstList}>
				<li>
					<div className={styles.listLink}>
						CRM for experts ( {constants('env')} )
					</div>
				</li>
				<li>
					<Button size="small" type="dashed" style={{ marginLeft: '12px' }}>
						<a
							href="mailto:techsupport@upgrad.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							Contact Support
						</a>
					</Button>
				</li>
			</ul>
		)}
		<ul className={styles.secondList}>
			<li>
				<div className={styles.listLink}>All rights reserved.</div>
			</li>
			<li>
				<div className={styles.listLink}>
					© 2015-2020, upGrad Education Private Limited.
				</div>
			</li>
		</ul>
	</Footer>
);

SiteFooter.propTypes = {
	isMobile: PropTypes.bool,
};

export default SiteFooter;
