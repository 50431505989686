import moment from 'moment-timezone';

import getCurrentTimeZone from './getCurrentTimeZone';

export default function convertToIST(localDateTime) {
	const localMoment = moment.tz(localDateTime, getCurrentTimeZone());
	const istMoment = localMoment.clone().tz('Asia/Kolkata');

	return istMoment;
}
