/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setCalendarEvents,
	setCalendarEventsHistory,
	setCalendarEventPosition,
	setCalendarMessages,
	setCohorts,
} from '../actions/calendarEvents';

const initialState = {
	cohorts: [],
	events: { data: [], headers: {} },
	meetingPosition: {},
	historyEvents: { data: [], headers: {} },
	messages: {
		recordId: null,
		getEventsError: {},
		getHistoryEventsError: {},
		createEventError: {},
		createEventSuccess: {},
		deleteEventError: {},
		deleteEventSuccess: '',
	},
};

const calendarEventsReducer = createReducer(initialState, {
	[setCohorts]: (state, action) => {
		state.cohorts = action.payload;
	},
	[setCalendarEvents]: (state, action) => {
		state.events = action.payload;
	},
	[setCalendarEventPosition]: (state, action) => {
		state.meetingPosition = action.payload;
	},
	[setCalendarEventsHistory]: (state, action) => {
		state.historyEvents = action.payload;
	},
	[setCalendarMessages]: (state, action) => {
		if (action.payload && action.payload.recordId) {
			state.events.data = state.events.data.filter(
				(item) => item.id !== action.payload.recordId,
			);
		}
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default calendarEventsReducer;
