import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { initialize } from 'utils/clevertap';
import ErrorBoundary from 'sharedComponents/ErrorBoundary';
import { constants } from 'utils/constants';
import { GatewayDest, GatewayProvider } from 'react-gateway';
import App from './App';
import { store, history } from './store/index';
import * as serviceWorker from './serviceWorker';

if (constants('env') === 'local') {
	// reason: standard library config
	// eslint-disable-next-line global-require
	import('react-axe').then((axe) => {
		axe.default(React, ReactDOM, 1000);
	});
} else {
	initialize({
		CLEVERTAP_ID: constants('clevertapId'),
	});
}
ReactDOM.render(
	<>
		<ErrorBoundary>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<GatewayProvider>
						<>
							<App />
							<GatewayDest name="modal" className="modal-container" />
						</>
					</GatewayProvider>
				</ConnectedRouter>
			</Provider>
		</ErrorBoundary>
	</>,
	document.getElementById('root'),
);

serviceWorker.register();
