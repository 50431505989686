import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { getCookie } from 'utils/cookies';
import rollbar from 'utils/rollbar';
import { constants } from 'utils/constants';

const crmApi = axios.create({
	baseURL: constants('crmApiServerUrl'),
	timeout: 0,
});

crmApi.interceptors.request.use((config) => {
	const newConfig = { ...config };
	newConfig.headers[constants('authTokenBackendKey')] = getCookie(
		`upgrad-auth-token.${constants('env')}`,
	);
	newConfig.headers.sessionId = getCookie(
		`upgrad-sessionId.${constants('env')}`,
	);

	return newConfig;
});

crmApi.interceptors.response.use(
	(response) => response,
	(error) => {
		const status = error.response?.status;
		if (status >= 403) {
			rollbar.error(
				`Backend Error [${error.response.status}] with crm API: `,
				error,
			);
		}
		return Promise.reject(error);
	},
);

export default crmApi;
