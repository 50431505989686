import mapKeys from 'utils/mapKeys';

export const features = mapKeys([
	// Home
	'HOME',

	// CRM:
	// Availability Management
	'AVAILABILITY_LIST_VIEW',

	// Calendar Management
	'CALENDAR_EVENT_LIST_VIEW',
	'CALENDAR_EVENT_CALENDAR_VIEW',
	'ADD_CALENDAR_EVENT',

	// Meeting Management
	'MEETING_EVENT_LIST_VIEW',

	// Mapping Management
	'EXPERT_MANAGEMENT_VIEW',
	'LEARNER_MANAGEMENT_VIEW',
	'GROUP_MANAGEMENT_VIEW',
	'EXPERT_GROUP_MANAGEMENT_VIEW',
	'LEARNER_GROUP_MANAGEMENT_VIEW',

	// Feedback Form Management
	'FEEDBACK_FORM_LIST_VIEW',

	// Communication Management
	'FEEDBACK_REMINDER_VIEW',
	'AVAILABILITY_REMINDER_VIEW',
	'MEETING_REMINDER_VIEW',

	// Request Management
	'REQUEST_LIST_VIEW',

	// Category Management
	'CATEGORY_LIST_VIEW',

	// Meeting Window management
	'MEETING_WINDOW',
	'MEETING_WINDOW_EDIT_UPDATE_ACCESS',

	// Users:
	// User Management
	'USER_LIST_VIEW',
	'BULK_ADD_USER',

	// Student Management
	'STUDENT_LIST_VIEW',
]);
