/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setFormList,
	setFormDetails,
	setFeedbackFormMessages,
} from '../actions/feedbackForm';

const initialState = {
	allForms: { data: [], headers: {} },
	formDetails: {},
	messages: {
		getFormListError: {},
		getFormDetailsError: {},
	},
};

const feedbackFormReducer = createReducer(initialState, {
	[setFormList]: (state, action) => {
		state.allForms = action.payload;
	},
	[setFormDetails]: (state, action) => {
		state.formDetails = action.payload;
	},
	[setFeedbackFormMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default feedbackFormReducer;
