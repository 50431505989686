import React from 'react';

const BulletListSquared = (props) => (
	<svg
		width="26"
		height="26"
		viewBox="0 0 26 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.71431 8.28571C8.71431 8.99579 8.13867 9.57143 7.4286 9.57143C6.71852 9.57143 6.14288 8.99579 6.14288 8.28571C6.14288 7.57564 6.71852 7 7.4286 7C8.13867 7 8.71431 7.57564 8.71431 8.28571Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.25"
		/>
		<path
			d="M7.4286 14.7143C8.13867 14.7143 8.71431 14.1386 8.71431 13.4285C8.71431 12.7185 8.13867 12.1428 7.4286 12.1428C6.71852 12.1428 6.14288 12.7185 6.14288 13.4285C6.14288 14.1386 6.71852 14.7143 7.4286 14.7143Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.25"
		/>
		<path
			d="M8.71431 18.5714C8.71431 19.2814 8.13867 19.8571 7.4286 19.8571C6.71852 19.8571 6.14288 19.2814 6.14288 18.5714C6.14288 17.8613 6.71852 17.2856 7.4286 17.2856C8.13867 17.2856 8.71431 17.8613 8.71431 18.5714Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.25"
		/>
		<path
			d="M12.1428 7.85718C11.6695 7.85718 11.2857 8.24094 11.2857 8.71432C11.2857 9.1877 11.6695 9.57146 12.1428 9.57146H19C19.4733 9.57146 19.8571 9.1877 19.8571 8.71432C19.8571 8.24094 19.4733 7.85718 19 7.85718H12.1428Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.25"
		/>
		<path
			d="M11.2857 13.8571C11.2857 13.3838 11.6695 13 12.1428 13H19C19.4733 13 19.8571 13.3838 19.8571 13.8571C19.8571 14.3305 19.4733 14.7143 19 14.7143H12.1428C11.6695 14.7143 11.2857 14.3305 11.2857 13.8571Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.25"
		/>
		<path
			d="M12.1428 18.1428C11.6695 18.1428 11.2857 18.5267 11.2857 19C11.2857 19.4733 11.6695 19.8571 12.1428 19.8571H19C19.4733 19.8571 19.8571 19.4733 19.8571 19C19.8571 18.5267 19.4733 18.1428 19 18.1428H12.1428Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.25"
		/>
		<path
			d="M1 4.42857C1 2.53502 2.53502 1 4.42857 1H21.5714C23.465 1 25 2.53502 25 4.42857V21.5714C25 23.465 23.465 25 21.5714 25H4.42857C2.53502 25 1 23.465 1 21.5714V4.42857ZM4.42857 2.71429C3.48181 2.71429 2.71429 3.48181 2.71429 4.42857V21.5714C2.71429 22.5182 3.48181 23.2857 4.42857 23.2857H21.5714C22.5182 23.2857 23.2857 22.5182 23.2857 21.5714V4.42857C23.2857 3.48181 22.5182 2.71429 21.5714 2.71429H4.42857Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.25"
		/>
	</svg>
);

export default BulletListSquared;
