import { accessMap } from './accessMap';

// allowed check
export const allowed = (feature, role) => {
	if (!accessMap[feature]) return true;
	if (!role) return false;

	return accessMap[feature].indexOf(role) >= 0;
};

export const allowedModule = (module, roleSet) => {
	if (!accessMap[module]) {
		return true;
	}
	if (roleSet.length <= 0) return false;

	return !!intersection(accessMap[module], roleSet).length;
};

export const allowedFeature = (features, roleSet) => {
	if (features.length === 0) return true;
	if (roleSet.length <= 0) return false;

	const featureAccesses = features.map((feature) => {
		if (!accessMap[feature]) {
			return true;
		}
		return !!intersection(accessMap[feature], roleSet).length;
	});

	// return true if any of the features are accessible
	return featureAccesses.includes(true);
};

const intersection = (arr1, arr2) => arr1.filter((val) => arr2.includes(val));
