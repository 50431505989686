import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const CircularSpinner = ({ strokeColor = 'none', fillColor = 'none' }) => (
	<div className="spinner_root">
		<svg className="spinner_svg" viewBox="0 0 50 50">
			<circle
				className="spinner_path"
				cx="25"
				cy="25"
				r="20"
				stroke={strokeColor}
				fill={fillColor}
				strokeWidth="5"
			/>
		</svg>
	</div>
);

CircularSpinner.propTypes = {
	fillColor: PropTypes.string,
	strokeColor: PropTypes.string,
};

export default CircularSpinner;
