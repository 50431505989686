import {
	getLearnerEventMappingList,
	getLearnerMappingUploadHistory,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setLearnerMappingList = createAction(
	'SET_LEARNER_MAPPING_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setLearnerUploadList = createAction(
	'SET_LEARNER_UPLOAD_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setLearnerMappingMessages = createAction(
	'SET_LEARNER_MAPPING_MESSAGES',
	(data) => ({ payload: data }),
);

export const getLearnerList = (query) => (dispatch) => {
	getLearnerEventMappingList(query)
		.then((response) => {
			dispatch(setLearnerMappingList(response));
		})
		.catch((err) => {
			dispatch(setLearnerMappingMessages({ getLearnerListError: err.response }));
		});
};

export const getLearnerUploadList = (query) => (dispatch) => {
	getLearnerMappingUploadHistory(query)
		.then((response) => {
			dispatch(setLearnerUploadList(response));
		})
		.catch((err) => {
			dispatch(setLearnerMappingMessages({ getLearnerUploadError: err.response }));
		});
};
