module.exports = {
	homePage: '/',
	loginPage: '/login',
	profilePage: '/profile/:userId',

	// CRM - Availability
	availabilityManagement__availabilityListView: '/crm/availability/list',

	// CRM - Calendar
	calendarManagement__calendarEventListView: '/crm/meeting/list',
	calendarManagement__calendarEventCalendarView: '/crm/meeting/calendar',
	calendarManagement__addCalendarEvent: '/crm/meeting/add',

	// CRM - Meeting
	meetingManagement__meetingEventListView: '/crm/event/list',

	// CRM - Mapping
	mappingManagement__expertManagementView: '/crm/mapping/expert',
	mappingManagement__learnerManagementView: '/crm/mapping/learner',
	mappingManagement__groupManagementView: '/crm/mapping/group',
	mappingManagement__learnerGroupManagementView: '/crm/mapping/learnerGroup',
	mappingManagement__expertGroupManagementView: '/crm/mapping/expertGroup',

	// CRM - Feedback Form
	feedbackFormManagement__feedbackFormListView: '/crm/feedbackForm/list',

	// CRM - Communication
	communicationManagement__feedbackReminderView: '/crm/communication/feedback',
	communicationManagement__meetingReminderView: '/crm/communication/meeting',
	communicationManagement__availabilityReminderView:
		'/crm/communication/availability',

	// CRM - Request Management
	requestManagement__requestListView: '/crm/request/list',

	// CRM - Category Management
	categoryManagement__categoryListView: '/crm/category/list',

	// Users - User Management
	userManagement__userListView: '/experts/expert/list',
	userManagement__bulkAddUser: '/experts/expert/bulkAdd',

	// Mentorship and Carrier Coaching
	meetingWindow__meetingWindowListView: '/crm/meetingWindow/list',

	// student list
	studentList_studentListView: '/crm/student/list',
};
