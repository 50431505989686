import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'sharedComponents/Input';
import Button from 'sharedComponents/Button/';
import styles from './index.module.scss';

const ChangePassword = () => {
	const [state, setState] = useState({
		isLoading: false,
		redirect: undefined,
		error: {
			show: false,
			message: '',
		},
	});

	const {
		global: {
			language: { changePasswordText },
		},
	} = useSelector((s) => s);
	const passwordChangeSchema = Yup.object().shape({
		oldPassword: Yup.string()
			.required(changePasswordText.passErrorMessage)
			.min(6),
		newPassword: Yup.string()
			.required(changePasswordText.passErrorMessage)
			.min(6),
		confirmPassword: Yup.string()
			.required(changePasswordText.passErrorMessage)
			.min(6),
	});
	const formik = useFormik({
		initialValues: { oldPassword: '', newPassword: '', confirmPasssword: '' },
		validationSchema: passwordChangeSchema,
		onSubmit: (values) => submit(values),
	});

	const submit = () => {
		setState({ ...state, isLoading: true });
		setState({
			...state,
			error: {
				show: true,
				message: 'Something went wrong. Contact upGrad support',
			},
			isLoading: false,
		});
	};

	const ViewError = () => (
		<div className={styles.error}>{state.error.message}</div>
	);

	const { isLoading } = state;
	return (
		<>
			<form className={styles.box} onSubmit={formik.handleSubmit}>
				<div className={styles.content}>
					<Input
						type="password"
						label={changePasswordText.oldPassword}
						name="oldPassword"
						placeholder={changePasswordText.yourPassword}
						className={styles.userType}
						onChange={({ event }) => formik.handleChange(event)}
						onBlur={({ event }) => formik.handleBlur(event)}
						value={formik.values.password}
						error={
							formik.errors.password && formik.touched.password
								? formik.errors.password
								: undefined
						}
					/>
					<Input
						type="password"
						label={changePasswordText.newPassword}
						name="newPassword"
						placeholder={changePasswordText.yourPassword}
						className={styles.userType}
						onChange={({ event }) => formik.handleChange(event)}
						onBlur={({ event }) => formik.handleBlur(event)}
						value={formik.values.password}
						error={
							formik.errors.password && formik.touched.password
								? formik.errors.password
								: undefined
						}
					/>
					<Input
						type="password"
						label={changePasswordText.confirmPasssword}
						name="confirmPasssword"
						placeholder={changePasswordText.yourPassword}
						className={styles.userType}
						onChange={({ event }) => formik.handleChange(event)}
						onBlur={({ event }) => formik.handleBlur(event)}
						value={formik.values.password}
						error={
							formik.errors.password && formik.touched.password
								? formik.errors.password
								: undefined
						}
					/>
				</div>
				{state.error.show && ViewError()}
				<div className={styles.footer}>
					<Button
						type="submit"
						label={changePasswordText.changePasswordButton}
						variant="primary"
						disabled={!formik.dirty}
						isLoading={isLoading}
						className={styles.submitBtn}
					/>
				</div>
			</form>
		</>
	);
};

export default ChangePassword;
