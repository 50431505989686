import crmApis from './axiosInstances/crm';

// Google APIs
export const generateAuthUrl = () => {
	const url = `/google/generateAuthUrl`;
	return crmApis.get(url);
};

export const storeAuthDetails = (code) => {
	const url = `/google/storeAuthDetails?code=${encodeURIComponent(code)}`;
	return crmApis.put(url);
};

// Consumer APIs
export const getCrmUser = (email) => {
	const url = `/consumer/idDetails/${encodeURIComponent(email)}`;
	return crmApis.get(url);
};

export const bulkUploadUsers = (payload) => {
	const url = `/consumer/bulkUpload`;
	return crmApis.post(url, payload);
};

export const getUserUploadHistory = (query) => {
	const url = `/consumer/getUploadHistory${query}`;
	return crmApis.get(url);
};

export const getUserList = (query) => {
	const url = `/consumer/list${query}`;
	return crmApis.get(url);
};

export const getUser = (id) => {
	const url = `/consumer/profile/${id}`;
	return crmApis.get(url);
};

export const saveUserProfile = (payload) => {
	const url = `/consumer/profile/update`;
	return crmApis.put(url, payload);
};

export const downloadUsersList = (query) => {
	const url = `/consumer/list/download${query}`;
	return crmApis.get(url, { responseType: 'blob' });
};

// Availability APIs
export const getScheduleTypes = () => {
	const url = `/availability/getScheduleTypes`;
	return crmApis.get(url);
};

export const getUserAvailability = (email) => {
	const url = `/availability/${email}`;
	return crmApis.get(url);
};

export const createAvailability = (payload) => {
	const url = `/availability/createOrUpdate`;
	return crmApis.post(url, payload);
};

export const checkAvailability = (query) => {
	const url = `/availability/${query}`;
	return crmApis.get(url);
};

// Calendar APIs
export const createEventOnCalendar = (payload) => {
	const url = `/meeting/create`;
	return crmApis.post(url, payload);
};

export const rescheduleEventOnCalendar = (payload) => {
	const url = `/meeting/reschedule`;
	return crmApis.post(url, payload);
};

export const getEventsOnCalendar = (query) => {
	const url = `/meeting/list${query}`;
	return crmApis.get(url);
};

export const getExpertEventsOnCalendar = (query) => {
	const url = `/meeting/listByExpert${query}`;
	return crmApis.get(url);
};

export const getMeetingPosition = (query) => {
	const url = `/meeting/positioning${query}`;
	return crmApis.get(url);
};

export const deleteEvent = (payload) => {
	const url = `/meeting/cancel`;
	return crmApis.put(url, payload);
};

export const dialUpMeet = (id) => {
	const url = `/kookoo/dialup?meetingId=${id}`;
	return crmApis.post(url);
};

export const dialUpEnd = (id) => {
	const url = `/kookoo/end-call?meetingId=${id}`;
	return crmApis.post(url);
};

export const getFeedbackPending = (id) => {
	const url = `/feedback/pending?meetingId=${id}`;
	return crmApis.get(url);
};

export const getFeedbackSubmittedInfoList = (meetingId) => {
	const url = `/feedback/student/list?meetingId=${meetingId}`;
	return crmApis.get(url);
};

export const getLearnerFeedback = (meetingId, learnerEmail) => {
	const url = `/feedback/expert?learnerEmail=${encodeURIComponent(
		learnerEmail,
	)}&meetingId=${meetingId}`;
	return crmApis.get(url);
};

export const bulkUploadEventsOnCalendar = (payload) => {
	const url = `/mappingManagement/eventCreation/bulkUpload`;
	return crmApis.post(url, payload);
};

export const getEventsOnCalendarUploadHistory = (query) => {
	const url = `/mappingManagement/eventCreation/getUploadHistory${query}`;
	return crmApis.get(url);
};

export const uploadRecording = (payload, id) => {
	const url = `/kookoo/upload-recording?meetingId=${id}`;
	return crmApis.post(url, payload);
};

// CRM-cohorts
export const getCohorts = () => {
	const url = `/mappingManagement/cohorts`;
	return crmApis.get(url);
};

// Meeting APIs
export const createEvent = (payload) => {
	const url = `/event/create`;
	return crmApis.post(url, payload);
};

export const getEvents = (query) => {
	const url = `/event/list${query}`;
	return crmApis.get(url);
};

// event types
export const getAllTypes = (query) => {
	const url = `/event-types${query}`;
	return crmApis.get(url);
};

export const getReasons = (query) => {
	const url = `/reasons/${query}`;
	return crmApis.get(url);
};

export const getEventsByType = (type) => {
	const url = `/event/list/${type}`;
	return crmApis.get(url);
};

export const getExpertsByType = (type) => {
	const url = `/mappingManagement/event/${type}/experts`;
	return crmApis.get(url);
};

export const getLearnerGroupFiltered = (query) => {
	const url = `/mappingManagement/learners/list/filter${query}`;
	return crmApis.get(url);
};

// Expert Management APIs
export const getExpertEventMappingList = (query) => {
	const url = `/mappingManagement/expertEventMapping/list${query}`;
	return crmApis.get(url);
};

export const downloadExpertList = (query) => {
	const url = `/mappingManagement/expertEventMapping/download${query}`;
	return crmApis.get(url, { responseType: 'blob' });
};

export const bulkUploadExpertMapping = (payload) => {
	const url = `/mappingManagement/expertEventMapping/bulkUpload`;
	return crmApis.post(url, payload);
};

export const getExpertMappingUploadHistory = (query) => {
	const url = `/mappingManagement/expertEventMapping/getUploadHistory${query}`;
	return crmApis.get(url);
};

export const getExpertIndustryDomains = () => {
	const url = `/consumer/domains`;
	return crmApis.get(url);
};

export const getExpertCoreSkills = () => {
	const url = `/consumer/coreskills`;
	return crmApis.get(url);
};

export const sendExpertFilterEmail = (payload) => {
	const url = `/mappingManagement/expertEventMapping/availability/search`;
	return crmApis.post(url, payload);
};

// expert Reschedule/Cancel APIs

export const rescheduleEventOnCalendarExpert = (payload) => {
	const url = `/meeting-request/reschedule`;
	return crmApis.post(url, payload);
};

export const cancelEventOnCalenderExpert = (payload) => {
	const url = `/meeting-request/cancel`;
	return crmApis.post(url, payload);
};

// Slot Management APIs
export const getExpertSlots = (query) => {
	const url = `/slotManagement/timeslots${query}`;
	return crmApis.get(url);
};

// Learner Management APIs
export const getLearnerEventMappingList = (query) => {
	const url = `/mappingManagement/learnerDataPoints/list${query}`;
	return crmApis.get(url);
};

export const downloadLearnerList = (query) => {
	const url = `/mappingManagement/learnerDataPoints/download${query}`;
	return crmApis.get(url, { responseType: 'blob' });
};

export const bulkUploadLearnerMapping = (payload) => {
	const url = `/mappingManagement/learnerDataPoints/bulkUpload`;
	return crmApis.post(url, payload);
};

export const getLearnerMappingUploadHistory = (query) => {
	const url = `/mappingManagement/learnerDataPoints/getUploadHistory${query}`;
	return crmApis.get(url);
};

export const createLearner = (payload) => {
	const url = `/mappingManagement/learnerDataPoints/`;
	return crmApis.post(url, payload);
};

export const updateLearner = (payload) => {
	const url = `/mappingManagement/learnerDataPoints/`;
	return crmApis.put(url, payload);
};

// Group Management APIs
export const getGroupEventMappingList = (query) => {
	const url = `/mappingManagement/cohort/group/list${query}`;
	return crmApis.get(url);
};

export const getPrograms = () => {
	const url = `/mappingManagement/cohorts/program-names`;
	return crmApis.get(url);
};

export const downloadGroupList = (query) => {
	const url = `/mappingManagement/cohort/group/download${query}`;
	return crmApis.get(url, { responseType: 'blob' });
};

export const bulkUploadGroupMapping = (payload) => {
	const url = `/mappingManagement/cohort/group/bulkUpload`;
	return crmApis.post(url, payload);
};

export const getGroupMappingUploadHistory = (query) => {
	const url = `/mappingManagement/cohort/group/getUploadHistory${query}`;
	return crmApis.get(url);
};

export const getGroupMappingDetails = (groupName) => {
	const url = `/mappingManagement/cohort/group/details/${groupName}`;
	return crmApis.get(url);
};

export const updateGroupMappingDetails = (payload) => {
	const url = `/mappingManagement/cohort/group/update`;
	return crmApis.put(url, payload);
};

export const updateGroupMappingStatus = (payload) => {
	const url = `/mappingManagement/cohort/group/status`;
	return crmApis.patch(url, payload);
};

export const getApplicableGroup = (query) => {
	const url = `/mappingManagement/cohort/group/applicable-groups${query}`;
	return crmApis.get(url);
};

// Expert Group Management APIs
export const getExpertGroupMappingList = (query) => {
	const url = `/mappingManagement/expertGroupMapping/list${query}`;
	return crmApis.get(url);
};

export const bulkUploadExpertGroupMapping = (payload) => {
	const url = `/mappingManagement/expertGroupMapping/bulkUpload`;
	return crmApis.post(url, payload);
};

export const getExpertGroupMappingUploadHistory = (query) => {
	const url = `/mappingManagement/expertGroupMapping/getUploadHistory${query}`;
	return crmApis.get(url);
};

export const deleteExpertMapping = (payload) => {
	const url = `/mappingManagement/expertEventMapping`;
	return crmApis.delete(url, { data: payload });
};

// Learner Group Management APIs
export const getLearnerGroupMappingList = (query) => {
	const url = `/mappingManagement/learnerGroupMapping/list${query}`;
	return crmApis.get(url);
};

export const bulkUploadLearnerGroupMapping = (payload) => {
	const url = `/mappingManagement/learnerGroupMapping/bulkUpload`;
	return crmApis.post(url, payload);
};

export const getLearnerGroupMappingUploadHistory = (query) => {
	const url = `/mappingManagement/learnerGroupMapping/getUploadHistory${query}`;
	return crmApis.get(url);
};

// feedback form filter
export const getAllForms = (query) => {
	const url = `/forms${query}`;
	return crmApis.get(url);
};

// feedback updation
export const updateFormStatus = (payload) => {
	const url = `/forms`;
	return crmApis.patch(url, payload);
};

// Communication Management APIs
export const getPendingFeedbacks = (query) => {
	const url = `/notification/feedbacks${query}`;
	return crmApis.get(url);
};

export const getPendingAvailability = (query) => {
	const url = `/notification/availability${query}`;
	return crmApis.get(url);
};

export const sendReminderNotification = (payload) => {
	const url = `/notification/send-notification`;
	return crmApis.post(url, payload);
};

// Request Management APIs
export const getRequests = (query) => {
	const url = `/meeting-request/list${query}`;
	return crmApis.get(url);
};

export const getRequestCounts = () => {
	const url = `/meeting-request/counts`;
	return crmApis.get(url);
};

export const approveChangeRequest = (payload) => {
	const url = `/meeting-request/${payload.id}/accept`;
	return crmApis.patch(url, payload);
};

export const rejectChangeRequest = (payload) => {
	const url = `/meeting-request/${payload.id}/reject`;
	return crmApis.patch(url, payload);
};

// Category Management
export const getCategories = (query) => {
	const url = `/categories/${query}`;
	return crmApis.get(url);
};

export const createCategory = (payload) => {
	const url = `/categories/`;
	return crmApis.post(url, payload);
};

export const updateCategory = (payload) => {
	const url = `/categories/`;
	return crmApis.put(url, payload);
};

export const deleteCategory = (query) => {
	const url = `/categories/${query}`;
	return crmApis.delete(url);
};

export const getCategoryName = () => {
	const url = `/categories/list`;
	return crmApis.get(url);
};

export const cancelChangeRequest = (query) => {
	const url = `/meeting-request/cancel-request${query}`;
	return crmApis.patch(url);
};

// Meeting Windows APIS
export const getMeetingWindow = (query) => {
	const url = `/meeting-windows/list${query}`;
	return crmApis.get(url);
};

export const addSlots = (payload) => {
	const url = `/meeting-windows/`;
	return crmApis.post(url, payload);
};

export const updateSlots = (payload) => {
	const url = `/meeting-windows/`;
	return crmApis.put(url, payload);
};

export const getMeetingWindowLearner = (id, query) => {
	const url = `/meeting-windows/list/${id}/${query}`;
	return crmApis.get(url);
};

export const getWindowEditDetails = (id) => {
	const url = `/meeting-windows/edit-details/${id}`;
	return crmApis.get(url);
};

export const deleteWindow = (id) => {
	const url = `/meeting-windows/${id}`;
	return crmApis.delete(url);
};

export const getOpenWindows = () => {
	const url = `/meeting-windows/open-windows`;
	return crmApis.get(url);
};

export const getMeetingWindowFromMeeting = (meetingId) => {
	const url = `meeting-windows/meeting-window-for-meeting/${meetingId}`;
	return crmApis.get(url);
};

// typeform API
export const getTypeform = (query) => {
	const url = `/typeform${query}`;
	return crmApis.get(url);
};

// learner resume
export const getLearnerResume = (userId) => {
	const url = `/dcs/resume/${userId}`;
	return crmApis.get(url, { observe: 'response', responseType: 'blob' });
};

// Alumni  APIs
export const getLeadDetails = (email) => {
	const url = `/lead/info?emailId=${encodeURIComponent(email)}`;
	return crmApis.get(url);
};

// Alumni APIs
export const getAlumniList = (query) => {
	const url = `/alumni/crm/list${query}`;
	return crmApis.get(url);
};

export const bulkUploadAlumni = (payload) => {
	const url = `/alumni/bulk/upload`;
	return crmApis.post(url, payload);
};

export const getCurrentDomains = () => {
	const url = `/alumni/crm/domain`;
	return crmApis.get(url);
};

export const downloadAlumniList = (query) => {
	const url = `/alumni/crm/list/download${query}`;
	return crmApis.get(url, { responseType: 'blob' });
};

export const rescheduleMeetingForAlumni = (payload) => {
	const url = `/alumni/reschedule/meeting`;
	return crmApis.post(url, payload);
};

export const cancelMeetingForAlumni = (payload) => {
	const url = `alumni/cancel/meeting`;
	return crmApis.put(url, payload);
};
