import React from 'react';

const DocumentEdit = (props) => (
	<svg
		width="21"
		height="26"
		viewBox="0 0 21 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.71392 0C1.66278 0 0 1.66278 0 3.71392V22.2835C0 24.3347 1.66278 25.9974 3.71392 25.9974H5.84279C5.59208 25.4418 5.49955 24.803 5.62458 24.1405H3.71392C2.68836 24.1405 1.85696 23.3091 1.85696 22.2835V3.71392C1.85696 2.68836 2.68836 1.85696 3.71392 1.85696H9.28479V6.49935C9.28479 8.03771 10.5319 9.28479 12.0702 9.28479H16.7126V11.2557C17.3226 11.1169 17.9557 11.1049 18.5696 11.2197V8.197C18.5696 7.45827 18.2762 6.74978 17.7538 6.2274L12.3422 0.815836C11.8198 0.293474 11.1113 0 10.3726 0H3.71392ZM16.328 7.42783H12.0702C11.5574 7.42783 11.1417 7.01213 11.1417 6.49935V2.24155L16.328 7.42783Z"
			fill="#4F8AFB"
		/>
		<path
			d="M15.8182 13.7899C16.8729 12.7351 18.5832 12.7351 19.6381 13.7899C20.6929 14.8448 20.6929 16.555 19.6381 17.6098L12.6629 24.5851C12.2562 24.9918 11.7466 25.2801 11.1886 25.4198L9.02522 25.9605C8.08439 26.1958 7.23218 25.3435 7.46738 24.4027L8.00824 22.2394C8.14773 21.6814 8.43625 21.1718 8.84292 20.7651L15.8182 13.7899Z"
			fill="#4F8AFB"
		/>
	</svg>
);

export default DocumentEdit;
