import React from 'react';

const Comment = (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M4.80001 2.40001C3.47454 2.40001 2.40001 3.47454 2.40001 4.80001V14.4C2.40001 15.7255 3.47454 16.8001 4.80001 16.8001H6.00002C6.66276 16.8001 7.20002 17.3373 7.20002 18.0001V20.5578L12.5344 17.0016C12.7315 16.8702 12.9631 16.8001 13.2 16.8001H19.2001C20.5256 16.8001 21.6001 15.7255 21.6001 14.4V4.80001C21.6001 3.47454 20.5256 2.40001 19.2001 2.40001H4.80001ZM0 4.80001C0 2.14904 2.14904 0 4.80001 0H19.2001C21.8511 0 24.0001 2.14904 24.0001 4.80001V14.4C24.0001 17.051 21.8511 19.2001 19.2001 19.2001H13.5634L6.66566 23.7985C6.29743 24.044 5.82398 24.0668 5.43378 23.858C5.04359 23.6492 4.80001 23.2426 4.80001 22.8001V19.2001C2.14904 19.2001 0 17.051 0 14.4V4.80001Z"
			fill="#4F8AFB"
		/>
	</svg>
);

export default Comment;
