import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Divider, Row, Card, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import googleCalendarIcon from 'images/google-calendar-icon.svg';

const AddCalendar = () => {
	const googleAuthUrl = useSelector((s) => s.user.googleAuthUrl);
	const userEmail = useSelector((s) => s.user.email);

	const [customHref, setCustomHref] = useState(googleAuthUrl);

	useEffect(() => {
		const customUrl = new URL(googleAuthUrl);
		customUrl.searchParams.set('redirect_uri', window.location.origin);
		customUrl.searchParams.set('state', window.location.pathname);
		customUrl.searchParams.set('login_hint', userEmail);
		setCustomHref(customUrl);
	}, [googleAuthUrl]);

	const handleAddCalendar = (e) => {
		e.preventDefault();
		window.location.href = customHref;
	};

	return (
		<div>
			<Row align="middle" justify="center">
				<Col>
					<Card bordered={false} style={{ textAlign: 'center' }}>
						<Space direction="vertical">
							<img src={googleCalendarIcon} height="64px" alt="google calendar" />
							<div style={{ fontSize: '24px', fontWeight: 500 }}>
								Connect your Google Calendar
							</div>
							<div style={{ fontSize: '18px' }}>
								Connect your calendar to provide availability and add new events as they
								are scheduled
								<Tooltip title="Calendar permissions are used for syncing newly created events. Existing details on your calendar are not used.">
									<InfoCircleOutlined style={{ marginLeft: '4px' }} />
								</Tooltip>
							</div>
							<div style={{ fontSize: '12px' }}>
								Note: Please sync calendar with email id {userEmail}{' '}
							</div>
						</Space>
					</Card>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col offset={12}>
					<Button
						type="primary"
						size="large"
						style={{
							backgroundColor: '#4F8AFB',
							borderRadius: '6px',
							padding: '0 36px',
							fontWeight: 500,
							fontSize: '16px',
						}}
						onClick={handleAddCalendar}
					>
						Sync Google Calendar
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default AddCalendar;
