import Rollbar from 'rollbar';
import { constants } from 'utils/constants';

const isLocal = /^(loc|LOC|local|LOCAL)$/.test(constants('env'));

// We jus keep one instance of Rollbar for every env, which can be
// filtered respective domain.
const rollbar = new Rollbar({
	accessToken: constants('rollbarAppKey'),
	captureUncaught: true,
	captureUnhandledRejections: true,
	autoInstrument: true,
	hostWhiteList: constants('domain'),
	environment: constants('env'),
	transmit: !isLocal,
	checkIgnore(isUncaught, args) {
		if (args[0] && args[0].response) {
			return /401|403|404/.test(args[0].response.status);
		}
		return false;
	},
	enabled: false,
	verbose: isLocal,
});

export default rollbar;
