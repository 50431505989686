import {
	getGroupEventMappingList,
	getPrograms,
	getGroupMappingUploadHistory,
	getGroupMappingDetails,
	getApplicableGroup,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setGroupMappingList = createAction(
	'SET_GROUP_MAPPING_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setProgramNames = createAction('SET_PROGRAM_NAMES', (data) => ({
	payload: data,
}));

export const setGroupDetails = createAction('SET_GROUP_DETAILS', (data) => ({
	payload: data,
}));

export const setGroupUploadList = createAction(
	'SET_GROUP_UPLOAD_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setGroupMappingMessages = createAction(
	'SET_GROUP_MAPPING_MESSAGES',
	(data) => ({ payload: data }),
);

export const setApplicableGroupDetails = createAction(
	'SET_APPLICABLE_GROUP_DETAILS',
	(data) => ({
		payload: data,
	}),
);

export const getGroupList = (query) => (dispatch) => {
	getGroupEventMappingList(query)
		.then((response) => {
			dispatch(setGroupMappingList(response));
		})
		.catch((err) => {
			dispatch(setGroupMappingMessages({ getGroupListError: err.response }));
		});
};

export const getProgramNames = () => (dispatch) => {
	getPrograms()
		.then((response) => {
			dispatch(setProgramNames(response.data));
		})
		.catch((err) => {
			dispatch(setGroupMappingMessages({ getProgramListError: err.response }));
		});
};

export const getGroupUploadList = (query) => (dispatch) => {
	getGroupMappingUploadHistory(query)
		.then((response) => {
			dispatch(setGroupUploadList(response));
		})
		.catch((err) => {
			dispatch(setGroupMappingMessages({ getGroupUploadError: err.response }));
		});
};

export const getGroupDetails = (groupName) => (dispatch) => {
	getGroupMappingDetails(groupName)
		.then((response) => {
			dispatch(setGroupDetails(response.data));
		})
		.catch((err) => {
			dispatch(setGroupMappingMessages({ getGroupDetailError: err.response }));
		});
};

export const getApplicableGroups = (query) => (dispatch) => {
	getApplicableGroup(query)
		.then((response) => {
			dispatch(setApplicableGroupDetails(response.data));
		})
		.catch((err) => {
			dispatch(
				setGroupMappingMessages({ getApplicableGroupDetailError: err.response }),
			);
		});
};
