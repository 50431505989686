import {
	getScheduleTypes,
	createAvailability,
	getUserAvailability,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

import formatSlotsToLocal from '../utils/formatSlotsToLocalTimezone';

export const setScheduleTypes = createAction('SET_SCHEDULE_TYPES', (data) => ({
	payload: data,
}));

export const setUserAvailability = createAction(
	'SET_USER_AVAILABILITY',
	(data) => ({
		payload: data,
	}),
);

export const setAvailabilityMessages = createAction(
	'SET_AVAILABILITY_MESSAGES',
	(data) => ({ payload: data }),
);

export const getScheduleRepeatTypes = () => (dispatch) => {
	getScheduleTypes()
		.then((response) => {
			dispatch(setScheduleTypes(response.data));
		})
		.catch((err) => {
			dispatch(setAvailabilityMessages({ getScheduleTypesError: err.response }));
		});
};

export const createUserAvailability = (payload) => (dispatch) => {
	createAvailability(payload)
		.then((res) =>
			dispatch(setAvailabilityMessages({ createAvailabilitySuccess: res.data })),
		)
		.catch((err) => {
			dispatch(setAvailabilityMessages({ createAvailabilityError: err.response }));
		});
};

export const getEmailAvailability = (email) => (dispatch) => {
	getUserAvailability(email)
		.then((response) => {
			const availability = response.data.data || [];

			const mappedAvailability = formatSlotsToLocal(availability);
			console.log(availability, mappedAvailability);
			dispatch(setUserAvailability(mappedAvailability));
		})
		.catch((err) => {
			dispatch(setAvailabilityMessages({ getAvailabilityError: err.response }));
		});
};
