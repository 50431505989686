/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setPendingFeedback,
	setPendingAvailability,
	setCommunicationMessages,
} from '../actions/communication';

const initialState = {
	pendingFeedbacks: { data: [], headers: {} },
	pendingAvailability: { data: [], headers: {} },
	messages: {
		getPendingFeedbackError: {},
		getPendingAvailabilityError: {},
	},
};

const communicationReducer = createReducer(initialState, {
	[setPendingFeedback]: (state, action) => {
		state.pendingFeedbacks = action.payload;
	},
	[setPendingAvailability]: (state, action) => {
		state.pendingAvailability = action.payload;
	},
	[setCommunicationMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default communicationReducer;
