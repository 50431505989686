/* eslint-disable */
import {
	setCategories,
	setCategoriesMessages,
	setCategoryName,
} from '../actions/category';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
	categories: { data: [], headers: {} },
	categoryName: [],
	messages: {
		recordId: null,
		getCategoriesError: {},
		getCategoryNameError: {},
	},
};

const categoryReducer = createReducer(initialState, {
	[setCategories]: (state, action) => {
		state.categories = action.payload;
	},
	[setCategoryName]: (state, action) => {
		state.categoryName = action.payload;
	},
	[setCategoriesMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default categoryReducer;
