import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Offline from 'sharedComponents/offline';
import Loader from 'sharedComponents/Loader';
import ErrorPages from 'sharedComponents/ErrorPages';
import { getCookie } from 'utils/cookies';
import { constants } from 'utils/constants';
import {
	authenticateUser,
	generateGoogleAuthUrl,
	getCrmUserDetails,
} from './actions/user';
import { getAllEventTypes } from './actions/meetingEvents';
import { getCrmCohorts } from './actions/calendarEvents';
import { getProgramNames } from './actions/groupMapping';
import {
	getUnreadNotificationCount,
	addRealTimeNotification,
} from './actions/notifications';
import { getConnectionOtp } from './apis/notificationManager';
import { features } from './accessControl/features';
import { allowedFeature } from './accessControl';
import routePaths from './routePaths';
import NavigationLayout from './layouts/NavigationLayout';
import { roles } from './accessControl/roles';
import './sass/index.scss';

const Login = React.lazy(() => import('./modules/auth/Login'));
const HomePage = React.lazy(() => import('./modules/HomePage'));

const MobileBlockerPage = React.lazy(() =>
	import('./modules/MobileBlockerPage'),
);
const ProfileView = React.lazy(() => import('./modules/ProfileView'));

// CRM - Availability
const AvailabilityManagementPage = React.lazy(() =>
	import('./modules/crm/AvailabilityManagement'),
);

// CRM - Calendar
const CalendarManagementPage = React.lazy(() =>
	import('./modules/crm/CalendarManagement'),
);

// CRM - Meeting
const MeetingManagementPage = React.lazy(() =>
	import('./modules/crm/MeetingManagement'),
);

// CRM - Mapping
const MappingManagementPage = React.lazy(() =>
	import('./modules/crm/MappingManagement'),
);

// CRM - Feedback Form
const FeedbackFormManagementPage = React.lazy(() =>
	import('./modules/crm/FeedbackFormManagement'),
);

// CRM - Communication
const CommunicationManagementPage = React.lazy(() =>
	import('./modules/crm/CommunicationManagement'),
);

// CRM - Request Management
const RequestManagementPage = React.lazy(() =>
	import('./modules/crm/RequestManagement'),
);

// CRM - Category Management
const CategoryManagementPage = React.lazy(() =>
	import('./modules/crm/CategoryManagement'),
);

const StudentManagementPage = React.lazy(() =>
	import('./modules/crm/StudentProfile'),
);

// Users - User Management
const UserManagementPage = React.lazy(() =>
	import('./modules/users/UserManagement'),
);

// CRM-Mentorship and Carrier Coaching
const MeetingWindowPage = React.lazy(() =>
	import('./modules/crm/MeetingWindow'),
);

const RouteWrapper = (props) => {
	const isAuthenticated = props.userInfo.id;
	const message =
		'You are not eligible for viewing this page on mobile. Please switch to desktop. 🙌';
	if (!isAuthenticated) {
		// will have to replace with auth service in future
		window.location.href = routePaths.loginPage;
	}
	if (props.hideForAdmin) {
		return (
			<Route
				render={() => <MobileBlockerPage message={message} redirect={false} />}
				isMobile={props.isMobile}
				redirect={false}
			/>
		);
	}
	if (props.renderInfoForSmallerScreen) {
		return <Route component={MobileBlockerPage} isMobile={props.isMobile} />;
	}

	if (props.protect) {
		if (allowedFeature(props.protect, props.userInfo?.rolesStringSet)) {
			return <Route {...props}>{props.children}</Route>;
		}
		return <Redirect to={routePaths.homePage} />;
	}

	return <Route {...props}>{props.children}</Route>;
};

RouteWrapper.propTypes = {
	userInfo: PropTypes.object,
	routePaths: PropTypes.object,
	protect: PropTypes.array,
	renderInfoForSmallerScreen: PropTypes.bool,
	hideForAdmin: PropTypes.bool,
	isMobile: PropTypes.bool,
	children: PropTypes.node,
};

const App = () => {
	const [lastEventId, setLastEventId] = React.useState('');

	const userInfo = useSelector((s) => s.user);
	const isMobile = useSelector((s) => s.global.isMobile);
	const location = useSelector((s) => s.router.location);
	const loading = useSelector((s) => s.global.loader) > 0;
	const dispatch = useDispatch();
	const tokenCheck =
		getCookie(`upgrad-auth-token.${constants('env')}`).length > 0;
	const condition = !tokenCheck || (tokenCheck && userInfo.id);

	const handleWindowSizeChange = () => {
		dispatch({ type: 'SET_IS_MOBILE', payload: window.innerWidth });
	};

	const setNotificationSseConnection = () => {
		getConnectionOtp().then((res) => {
			const eventSource = new EventSource(
				`${constants(
					'notificationManagerApiServerUrl',
				)}/notifManager/notificationWatch?otp=${
					res.otp
				}&lastEventId=${lastEventId}`,
				{ withCredentials: true },
			);
			// eslint-disable-next-line no-console
			eventSource.onopen = () => console.log('connection established!');
			eventSource.onmessage = (e) => {
				const data = JSON.parse(e.data);
				if (data.id) {
					dispatch(addRealTimeNotification(data));
					setLastEventId(e.id || lastEventId);
				}
			};
			eventSource.onerror = () => {
				eventSource.close();
				setNotificationSseConnection();
			};
		});
	};

	React.useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		handleWindowSizeChange();
		if (tokenCheck && !userInfo.id) {
			dispatch(authenticateUser());
		}
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		if (userInfo.id) {
			dispatch(generateGoogleAuthUrl());
			dispatch(getUnreadNotificationCount());
			dispatch(getCrmUserDetails(userInfo.email));
			dispatch(getAllEventTypes(`?isActive=true`));
			dispatch(getCrmCohorts());
			dispatch(getProgramNames());
			setNotificationSseConnection();
		}
	}, [userInfo.id]);

	const isAdminUser = [roles.ADMIN, roles.PA].some((role) =>
		userInfo.rolesStringSet.includes(role),
	);

	return (
		<>
			<Offline />
			{condition && (
				<React.Suspense fallback={<Loader />}>
					<Switch>
						<Route path={routePaths.loginPage} exact>
							<Login />
						</Route>

						<NavigationLayout isMobile={isMobile} location={location}>
							<Switch>
								{/* Dashboard */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={false}
									path={routePaths.homePage}
									userInfo={userInfo}
									exact
								>
									<HomePage />
								</RouteWrapper>

								{/* Profile */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={false}
									path={routePaths.profilePage}
									userInfo={userInfo}
									exact
								>
									<ProfileView />
								</RouteWrapper>

								{/* CRM - Availability */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.availabilityManagement__availabilityListView}
									userInfo={userInfo}
									protect={[features.AVAILABILITY_LIST_VIEW]}
									exact
								>
									<AvailabilityManagementPage />
								</RouteWrapper>

								{/* CRM - Calendar */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.calendarManagement__calendarEventListView}
									userInfo={userInfo}
									protect={[features.CALENDAR_EVENT_LIST_VIEW]}
									exact
								>
									<CalendarManagementPage />
								</RouteWrapper>
								<RouteWrapper
									// renderInfoForSmallerScreen={isMobile}
									hideForAdmin={isAdminUser && isMobile}
									path={routePaths.calendarManagement__calendarEventCalendarView}
									userInfo={userInfo}
									protect={[features.CALENDAR_EVENT_CALENDAR_VIEW]}
									exact
								>
									<CalendarManagementPage />
								</RouteWrapper>
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									// renderInfoForSmallerScreen={isMobile}
									path={routePaths.calendarManagement__addCalendarEvent}
									userInfo={userInfo}
									protect={[features.ADD_CALENDAR_EVENT]}
									exact
								>
									<CalendarManagementPage />
								</RouteWrapper>

								{/* CRM - Meeting */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.meetingManagement__meetingEventListView}
									userInfo={userInfo}
									protect={[features.MEETING_EVENT_LIST_VIEW]}
									exact
								>
									<MeetingManagementPage />
								</RouteWrapper>

								{/* CRM - Mapping */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.mappingManagement__expertManagementView}
									userInfo={userInfo}
									protect={[features.EXPERT_MANAGEMENT_VIEW]}
									exact
								>
									<MappingManagementPage />
								</RouteWrapper>
								<RouteWrapper
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.mappingManagement__learnerManagementView}
									userInfo={userInfo}
									protect={[features.LEARNER_MANAGEMENT_VIEW]}
									exact
								>
									<MappingManagementPage />
								</RouteWrapper>
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.mappingManagement__groupManagementView}
									userInfo={userInfo}
									protect={[features.GROUP_MANAGEMENT_VIEW]}
									exact
								>
									<MappingManagementPage />
								</RouteWrapper>
								{/* <RouteWrapper
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.mappingManagement__expertGroupManagementView}
									userInfo={userInfo}
									protect={[features.EXPERT_GROUP_MANAGEMENT_VIEW]}
									exact
								>
									<MappingManagementPage />
								</RouteWrapper> */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.mappingManagement__learnerGroupManagementView}
									userInfo={userInfo}
									protect={[features.LEARNER_GROUP_MANAGEMENT_VIEW]}
									exact
								>
									<MappingManagementPage />
								</RouteWrapper>

								{/* CRM - Feedback Form */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.feedbackFormManagement__feedbackFormListView}
									userInfo={userInfo}
									protect={[features.MEETING_EVENT_LIST_VIEW]}
									exact
								>
									<FeedbackFormManagementPage />
								</RouteWrapper>

								{/* CRM - Communication */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.communicationManagement__feedbackReminderView}
									userInfo={userInfo}
									protect={[features.FEEDBACK_REMINDER_VIEW]}
									exact
								>
									<CommunicationManagementPage />
								</RouteWrapper>
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.communicationManagement__meetingReminderView}
									userInfo={userInfo}
									protect={[features.MEETING_REMINDER_VIEW]}
									exact
								>
									<CommunicationManagementPage />
								</RouteWrapper>
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.communicationManagement__availabilityReminderView}
									userInfo={userInfo}
									protect={[features.AVAILABILITY_REMINDER_VIEW]}
									exact
								>
									<CommunicationManagementPage />
								</RouteWrapper>

								{/* CRM - Request Management */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.requestManagement__requestListView}
									userInfo={userInfo}
									protect={[features.REQUEST_LIST_VIEW]}
									exact
								>
									<RequestManagementPage />
								</RouteWrapper>

								{/* CRM - Category Management */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.categoryManagement__categoryListView}
									userInfo={userInfo}
									protect={[features.CATEGORY_LIST_VIEW]}
									exact
								>
									<CategoryManagementPage />
								</RouteWrapper>

								{/* Student- studentManagement */}
								<RouteWrapper
									hideForAdmin={isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.studentList_studentListView}
									userInfo={userInfo}
									protect={[features.STUDENT_LIST_VIEW]}
									exact
								>
									<StudentManagementPage />
								</RouteWrapper>

								{/* Users - UserManagement */}
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.userManagement__userListView}
									userInfo={userInfo}
									protect={[features.USER_LIST_VIEW]}
									exact
								>
									<UserManagementPage />
								</RouteWrapper>
								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									renderInfoForSmallerScreen={isMobile}
									path={routePaths.userManagement__bulkAddUser}
									userInfo={userInfo}
									protect={[features.BULK_ADD_USER]}
									exact
								>
									<UserManagementPage />
								</RouteWrapper>
								{/* CRM - meeting window Management */}

								<RouteWrapper
									hideForAdmin={isAdminUser && isMobile}
									// renderInfoForSmallerScreen={isMobile}
									path={routePaths.meetingWindow__meetingWindowListView}
									userInfo={userInfo}
									protect={[features.MEETING_WINDOW]}
									exact
								>
									<MeetingWindowPage />
								</RouteWrapper>
							</Switch>
						</NavigationLayout>

						<Route path="/error/:code">
							<ErrorPages />
						</Route>
						<Route path="*">
							<ErrorPages code="404" />
						</Route>
					</Switch>
				</React.Suspense>
			)}
			{(loading || !condition) && <Loader />}
		</>
	);
};

export default App;
