// eslint-disable-next-line import/named
import { getFeedbackSubmittedInfoList, getLearnerFeedback } from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setFeedbackSubmittedInfoList = createAction(
	'SET_FEEDBACK_SUBMITTED_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setLearnerFeedback = createAction(
	'SET_LEARNER_FEEDBACK',
	(data) => ({ payload: { [data.sourceId]: data } }),
);

export const setLoader = createAction('SET_LOADER', (data) => ({
	payload: data,
}));

export const setFeedbackMessages = createAction(
	'SET_FEEDBACK_MESSAGES',
	(data) => ({ payload: data }),
);

export const setFeedbackListEmpty = createAction('SET_FEEDBACK_LIST_EMPTY');

export const setFeedbackSubmittedInfoEmpty = createAction(
	'SET_FEEDBACK_SUBMITTED_INFO_EMPTY',
);

export const getFeedbackSubmittedList = (meetingId) => (dispatch) => {
	getFeedbackSubmittedInfoList(meetingId)
		.then((response) => {
			dispatch(setFeedbackSubmittedInfoList(response.data.data));
		})
		.catch((err) => {
			dispatch(setFeedbackMessages({ getFormListError: err.response }));
		});
};

export const getLearnerFeedbackDetails =
	(meetingId, learnerEmail) => (dispatch) => {
		dispatch(setLoader(true));
		getLearnerFeedback(meetingId, learnerEmail)
			.then((response) => {
				dispatch(setLearnerFeedback(response.data.data));
				return response;
			})
			.catch((err) => {
				dispatch(setFeedbackMessages({ getFormDetailsError: err.response }));
			})
			.finally(() => {
				dispatch(setLoader(false));
			});
	};
