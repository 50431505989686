import React from 'react';

const DeviceMeetingRoomRemote = (props) => (
	<svg
		width="27"
		height="26"
		viewBox="0 0 27 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M3.6535 4.11352C4.06739 2.29237 5.68643 1 7.55403 1H20.0454C21.913 1 23.5321 2.29237 23.9458 4.11352L26.4913 15.3134C27.0602 17.8166 25.1577 20.2 22.5908 20.2H12.1999V18.6H22.5908C24.131 18.6 25.2724 17.1699 24.9311 15.6682L22.3857 4.46811C22.1374 3.37542 21.166 2.6 20.0454 2.6H7.55403C6.43347 2.6 5.46205 3.37542 5.21371 4.46811L4.54738 7.4H3.39986C3.23042 7.4 3.06344 7.41054 2.89954 7.43099L3.6535 4.11352Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.35"
		/>
		<path
			d="M12.12 23.3998H21.0001C21.4418 23.3998 21.8001 23.0416 21.8001 22.5998C21.8001 22.158 21.4418 21.7998 21.0001 21.7998H12.2V22.5998C12.2 22.8737 12.1725 23.1412 12.12 23.3998Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.35"
		/>
		<path
			d="M5.79995 15.4002C6.6836 15.4002 7.39995 14.6839 7.39995 13.8002C7.39995 12.9165 6.6836 12.2002 5.79995 12.2002C4.9163 12.2002 4.19995 12.9165 4.19995 13.8002C4.19995 14.6839 4.9163 15.4002 5.79995 15.4002Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.35"
		/>
		<path
			d="M1 11.4C1 10.0745 2.07451 9 3.4 9H8.2C9.52549 9 10.6 10.0745 10.6 11.4V22.6C10.6 23.9254 9.52549 25 8.2 25H3.4C2.07451 25 1 23.9254 1 22.6V11.4ZM3.4 10.6C2.95818 10.6 2.6 10.9582 2.6 11.4V22.6C2.6 23.0418 2.95818 23.4 3.4 23.4H8.2C8.64182 23.4 9 23.0418 9 22.6V11.4C9 10.9582 8.64182 10.6 8.2 10.6H3.4Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.35"
		/>
	</svg>
);

export default DeviceMeetingRoomRemote;
