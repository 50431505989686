import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import moment from 'moment';
import NotificationItem from '../NotificationItem';
import styles from './index.module.scss';

const NotificationList = ({
	currentTab,
	setIsNotificationsVisible,
	notifications,
}) => {
	const generateCategorisedList = () => {
		let count = 0;
		let lastDiff = null;
		let categorisedDisplayList = notifications.map((notifItem) => {
			let dayCategory = '';
			let displayItem = null;
			if (notifItem.category.indexOf(currentTab) >= 0) {
				if (currentTab === 'IMPORTANT') {
					dayCategory = count === 0 ? 'Actionable for today' : null;
				} else {
					const diff = moment().diff(moment(notifItem.created), 'days');
					if (lastDiff !== diff) {
						switch (diff) {
							case 0:
								dayCategory = 'Today';
								break;
							case 1:
								dayCategory = 'Yesterday';
								break;
							default:
								dayCategory = `${diff} days ago`;
						}
						lastDiff = diff;
					}
				}
				displayItem = (
					<div key={notifItem.id}>
						{dayCategory ? (
							<div className={styles.headingText}> {dayCategory} </div>
						) : null}
						<NotificationItem
							setIsNotificationsVisible={setIsNotificationsVisible}
							notificationItem={notifItem}
						/>
					</div>
				);
				count++;
			}
			return displayItem;
		});
		if (!count) {
			categorisedDisplayList = <Empty description="No new notifications!" />;
		}
		return categorisedDisplayList;
	};

	return (
		<div className={styles.root}>
			{notifications.length ? (
				generateCategorisedList()
			) : (
				<Empty description="No new notifications!" />
			)}
		</div>
	);
};

NotificationList.propTypes = {
	currentTab: PropTypes.string,
	setIsNotificationsVisible: PropTypes.func,
	notifications: PropTypes.array,
};

export default NotificationList;
