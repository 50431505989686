import React from 'react';
import { Tooltip, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import useTimezoneSelection from './useTimezonSelection';

const TimezoneSelect = () => {
	const { displayTimeZone } = useTimezoneSelection();

	return (
		<>
			<div className={styles.root} style={{ position: 'absolute', right: 0 }}>
				<div className={styles.timeZone}>{displayTimeZone()}</div>
				<Tooltip
					placement="left"
					title="Based on the user’s system timings, the timezone will be auto-selected"
				>
					<Button type="text" icon={<InfoCircleOutlined />} shape="circle" />
				</Tooltip>
			</div>
		</>
	);
};

export default TimezoneSelect;
