import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import {
	Avatar,
	Button as AntButton,
	Dropdown,
	Menu,
	Typography,
	Popover,
	Modal,
	Badge,
	Drawer,
	Tooltip,
	notification,
} from 'antd';
import moment from 'moment';
import { AllAppsNavIcon } from 'images/navigationIcons';
import avaLogoIcon from 'images/upgrad/ava-logo-icon.svg';
import adminLogoIcon from 'images/upgrad/admin-logo-icon.svg';
import testsLogoIcon from 'images/upgrad/tests-logo-icon.svg';
import zeusLogoIcon from 'images/upgrad/zeus-logo-icon.svg';
import apolloLogoIcon from 'images/upgrad/apollo-logo-icon.svg';
import { DownOutlined, CheckCircleFilled, BellFilled } from '@ant-design/icons';
import { roles } from '../../accessControl/roles';
import ChangePassword from '../../modules/auth/ChangePassword';
import AddCalendar from '../../modules/crm/AddCalendar';
import NotificationView from '../../modules/NotificationView';
import * as allUserActions from '../../actions/user';
import { redirect } from '../../actions/navigation';
import { markNotificationsSeen } from '../../actions/notifications';
import { setCalendarEventPosition } from '../../actions/calendarEvents';
import { storeAuthDetails, getMeetingPosition } from '../../apis/crm';
import styles from './index.module.scss';
const { Text } = Typography;

const ProfileMenu = ({
	userInfo,
	userActions,
	isMobile,
	handleProfileClick,
}) => {
	const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
	const [isCalendarModalVisible, setIsCalendarModalVisible] = useState(false);
	const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);
	const [isDetailsProcessing, setIsDetailsProcessing] = useState(false);

	const location = useSelector((s) => s.router.location);
	const userCalPermission = useSelector((s) => s.user.calenderPermission);
	const unreadNotificationCount = useSelector((s) => s.notification.count);
	const realTimeNotification = useSelector(
		(s) => s.notification.newNotification,
	);

	const dispatch = useDispatch();

	const isAdminUser = () =>
		[roles.ADMIN, roles.PA].some((role) =>
			userInfo.rolesStringSet.includes(role),
		);

	useEffect(() => {
		if (location.query.code) {
			storeAuthDetails(location.query.code)
				.then(() => dispatch(userActions.getCrmUserDetails(userInfo.email)))
				.catch((err) => Promise.reject(err));
			dispatch(redirect(location.query.state));
		}
	}, [location.query]);

	const handleUserLogout = () => {
		userActions.logoutUser();
	};

	const getBodyText = (bodyText, initTime, params) => {
		let newText = bodyText;
		if (initTime) {
			newText = newText.replace(
				'<date_time>',
				moment(initTime).format('YYYY-MM-DD HH:mm'),
			);
		}
		if (params && params.newStartTimeUtc) {
			newText = newText.replace(
				'<new_date_time>',
				moment(params.newStartTimeUtc).format('YYYY-MM-DD HH:mm'),
			);
		}
		return <div style={{ color: '#7C8698' }}> {newText} </div>;
	};

	useEffect(() => {
		if (realTimeNotification.id) {
			notification.open({
				style: { borderRadius: '16px', color: '#fff', backgroundColor: '#1A202C' },
				icon: (
					<img
						className={styles.iconHolder}
						src={realTimeNotification.imageUrl}
						alt="toastNotification"
					/>
				),
				message: (
					<div style={{ color: '#fff', fontSize: '16px' }}>
						{realTimeNotification.title}
					</div>
				),
				closeIcon: <div style={{ color: '#fff' }}> x </div>,
				description: getBodyText(
					realTimeNotification.body,
					realTimeNotification.initTime,
					realTimeNotification.params,
				),
				key: realTimeNotification.id,
				placement: 'bottomLeft',
				btn: realTimeNotification.action ? (
					<AntButton
						style={{ fontSize: '18px' }}
						type="link"
						loading={isDetailsProcessing}
						onClick={() => {
							if (realTimeNotification.params?.id) {
								setIsDetailsProcessing(true);
								getMeetingPosition(`?meetingId=${realTimeNotification.params.id}`)
									.then((res) => {
										setIsDetailsProcessing(false);
										if (res.data.meetingStatus !== 'CANCELLED') {
											dispatch(setCalendarEventPosition(res.data));
										}
										notification.close(realTimeNotification.id);
										dispatch(redirect(realTimeNotification.action.destination));
										dispatch(markNotificationsSeen([realTimeNotification.id]));
									})
									.catch(() => setIsDetailsProcessing(false));
							} else {
								notification.close(realTimeNotification.id);
								dispatch(redirect(realTimeNotification.action.destination));
								dispatch(markNotificationsSeen([realTimeNotification.id]));
							}
						}}
					>
						{realTimeNotification.action.label}
					</AntButton>
				) : (
					false
				),
			});
		}
	}, [realTimeNotification]);

	const userMenu = (
		<Menu style={{ minWidth: '200px' }}>
			<Menu.Item onClick={handleProfileClick}>
				<div className={styles.avatarWrapper}>
					<Avatar
						size="large"
						style={{ margin: '20px 8px 20px 0' }}
						src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
						alt="avatar"
					/>
					<span>
						{userInfo.name}
						{userCalPermission ? (
							<Tooltip title="Calendar synced" placement="top">
								<Badge
									count={
										<CheckCircleFilled
											style={{ color: '#52c41a', paddingLeft: '20px' }}
										/>
									}
								/>
							</Tooltip>
						) : null}
					</span>

					<span>{userInfo.email}</span>
				</div>
			</Menu.Item>
			<Menu.Divider />
			{/* <Menu.ItemGroup title="SETTINGS">
				{isAdminUser() || userInfo.rolesStringSet.includes(roles.EXPERT) ? (
					<Menu.Item onClick={() => setIsCalendarModalVisible(true)}>
						<span>Sync Calendar</span>
					</Menu.Item>
				) : null}
			</Menu.ItemGroup> */}
			<Menu.ItemGroup title="HELP">
				<Menu.Item>
					<a
						href="mailto:techsupport@upgrad.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						Contact Support
					</a>
				</Menu.Item>
			</Menu.ItemGroup>
			<Menu.Divider />
			<Menu.Item onClick={handleUserLogout}>
				<Text type="danger">Logout</Text>
			</Menu.Item>
		</Menu>
	);

	return (
		<div
			className={cx(styles.root, {
				[styles.isMobile]: isMobile,
			})}
		>
			{isAdminUser() && !isMobile ? (
				<div className={styles.allAppIcon}>
					<Popover
						content={
							<div className={styles.popupMenuWrapper}>
								<a href="https://zeus.upgrad.com" className={styles.popupMenuItem}>
									<div className={styles.info}>
										<div className={styles.img}>
											<img src={zeusLogoIcon} alt="zues" />
										</div>
										<div className={styles.detail}>
											<div className={styles.title}>Zeus</div>
											<div className={styles.desc}>upGrad Learn</div>
										</div>
									</div>
								</a>
								<a href="https://ava.upgrad.com" className={styles.popupMenuItem}>
									<div className={styles.info}>
										<div className={styles.img}>
											<img src={avaLogoIcon} alt="ava" />
										</div>
										<div className={styles.detail}>
											<div className={styles.title}>Ava</div>
											<div className={styles.desc}>upGrad Careers & Outcome</div>
										</div>
									</div>
								</a>
								<a href="https://tests.upgrad.com" className={styles.popupMenuItem}>
									<div className={styles.info}>
										<div className={styles.img}>
											<img src={testsLogoIcon} alt="tests" />
										</div>
										<div className={styles.detail}>
											<div className={styles.title}>Tests</div>
											<div className={styles.desc}>upGrad - Apollo</div>
										</div>
									</div>
								</a>
								<a href="https://apollo.upgrad.com" className={styles.popupMenuItem}>
									<div className={styles.info}>
										<div className={styles.img}>
											<img src={apolloLogoIcon} alt="apollo" />
										</div>
										<div className={styles.detail}>
											<div className={styles.title}>Apollo</div>
											<div className={styles.desc}>upGrad - Test as a Service</div>
										</div>
									</div>
								</a>
								<a href="https://admin.upgrad.com" className={styles.popupMenuItem}>
									<div className={styles.info}>
										<div className={styles.img}>
											<img src={adminLogoIcon} alt="admin" />
										</div>
										<div className={styles.detail}>
											<div className={styles.title}>Admin (Legacy)</div>
											<div className={styles.desc}>upGrad - Old Admin Panel</div>
										</div>
									</div>
								</a>
							</div>
						}
						trigger="click"
						placement="bottom"
						style={{
							borderRadius: '6px',
							boxShadow: '0 10px 40px rgba(0, 0, 0, 0.15)',
							transform: 'none',
						}}
					>
						<img src={AllAppsNavIcon} alt="all" />
					</Popover>
				</div>
			) : null}

			<div className={styles.notificationWrapper}>
				<Badge
					count={unreadNotificationCount}
					size="small"
					title="Unread important notifications"
					dot={false}
					onClick={() => setIsNotificationsVisible(true)}
					style={{ borderRadius: '4px' }}
				>
					<BellFilled
						style={{ color: 'white', fontSize: '24px', verticalAlign: 'middle' }}
					/>
				</Badge>
				<Drawer
					title={
						<>
							<span className={styles.notifTitle}> Notifications </span>
							<Badge
								count={unreadNotificationCount}
								title="Unread important notifications"
								dot={false}
								style={{ backgroundColor: '#1A202C', borderRadius: '4px' }}
							/>
						</>
					}
					placement="right"
					closable
					destroyOnClose
					visible={isNotificationsVisible}
					onClose={() => setIsNotificationsVisible(false)}
					width={!isMobile ? '576' : '320'}
					headerStyle={{ borderBottom: 'none', paddingLeft: '32px' }}
					bodyStyle={{ padding: 0 }}
				>
					<NotificationView setIsNotificationsVisible={setIsNotificationsVisible} />
				</Drawer>
			</div>

			{!isMobile && (
				<div className={styles.profileMenuWrapper}>
					<Dropdown overlay={userMenu}>
						<button
							className={cx(styles.menuLink, 'ant-dropdown-link')}
							onClick={(e) => e.preventDefault()}
						>
							<Avatar
								size="small"
								style={{ margin: '20px 8px 20px 0' }}
								src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
								alt="avatar"
							/>
							{userInfo.name} <DownOutlined />
						</button>
					</Dropdown>
				</div>
			)}

			<Modal
				visible={isPasswordModalVisible}
				title="Change Password"
				onCancel={() => setIsPasswordModalVisible(false)}
				footer={null}
			>
				<ChangePassword />
			</Modal>

			<Modal
				visible={isCalendarModalVisible}
				onCancel={() => setIsCalendarModalVisible(false)}
				footer={null}
			>
				<AddCalendar />
			</Modal>
		</div>
	);
};

ProfileMenu.propTypes = {
	userInfo: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,
	isMobile: PropTypes.bool,
	handleProfileClick: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(allUserActions, dispatch),
	};
}

export default connect(null, mapDispatchToProps)(ProfileMenu);
