import { getPendingFeedbacks, getPendingAvailability } from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setPendingFeedback = createAction(
	'SET_PENDING_FEEDBACK',
	(data) => ({
		payload: data,
	}),
);

export const setPendingAvailability = createAction(
	'SET_PENDING_AVAILABILITY',
	(data) => ({
		payload: data,
	}),
);

export const setCommunicationMessages = createAction(
	'SET_COMMUNICATION_MESSAGES',
	(data) => ({
		payload: data,
	}),
);

export const getPendingFeedbackList = (query) => (dispatch) => {
	getPendingFeedbacks(query)
		.then((response) => {
			dispatch(setPendingFeedback(response));
		})
		.catch((err) => {
			dispatch(
				setCommunicationMessages({ getPendingFeedbackError: err.response }),
			);
		});
};

export const getPendingAvailabilityList = (query) => (dispatch) => {
	getPendingAvailability(query)
		.then((response) => {
			dispatch(setPendingAvailability(response));
		})
		.catch((err) => {
			dispatch(
				setCommunicationMessages({ getPendingAvailabilityError: err.response }),
			);
		});
};
