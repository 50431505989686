/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setNotificationList,
	setRealTimeNotification,
	setUnreadNotificationCount,
	updateNotificationsStatus,
	setNotificationMessages,
} from '../actions/notifications';

const initialState = {
	allNotifications: [],
	newNotification: {},
	count: 0,
	messages: {
		getNotificationListError: {},
		getNotificationCountError: {},
		markNotificationsSeenError: {},
	},
};

const getNotifsAfterMarking = (allNotifs, idList) => {
	allNotifs.map((notification) => {
		if (idList.indexOf(notification.id) >= 0) {
			notification.seen = true;
		}
	});
	return [...allNotifs];
};

const notificationsReducer = createReducer(initialState, {
	[setNotificationList]: (state, action) => {
		state.allNotifications = action.payload;
	},
	[setUnreadNotificationCount]: (state, action) => {
		state.count = action.payload;
	},
	[updateNotificationsStatus]: (state, action) => {
		state.allNotifications = getNotifsAfterMarking(
			state.allNotifications,
			action.payload,
		);
		state.allNotifications;
	},
	[setRealTimeNotification]: (state, action) => {
		state.count += 1;
		state.allNotifications = [action.payload, ...state.allNotifications];
		state.newNotification = action.payload;
	},
	[setNotificationMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default notificationsReducer;
