import React from 'react';

const HatGraduation = (props) => (
	<svg
		width="29"
		height="26"
		viewBox="0 0 29 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M17.5158 1.80445C15.8755 0.731872 13.7559 0.731852 12.1155 1.80437L1.44678 8.77995C1.17035 8.9607 1.00267 9.26773 1.00003 9.598C0.99974 9.63451 1.00147 9.67076 1.00514 9.70662C1.00206 9.73838 1.00048 9.77059 1.00048 9.80317V17.6976C1.00048 18.2425 1.44229 18.6844 1.98729 18.6844C2.53228 18.6844 2.9741 18.2425 2.9741 17.6976V11.4658L4.94771 12.8017V20.066C4.94771 20.3002 5.03216 20.528 5.18381 20.7064L5.18531 20.7082L5.18922 20.7127L5.20079 20.7261L5.23886 20.7692C5.27083 20.8047 5.31601 20.8542 5.37407 20.9152C5.49014 21.0374 5.65815 21.2069 5.87582 21.4078C6.31051 21.8091 6.94722 22.3394 7.76798 22.8689C9.40737 23.9266 11.8116 25 14.816 25C17.8203 25 20.2245 23.9264 21.8638 22.8689C22.6846 22.3394 23.3213 21.8091 23.7559 21.4078C23.9735 21.2069 24.1415 21.0374 24.2575 20.9152C24.3156 20.854 24.3608 20.8047 24.3927 20.7692L24.4308 20.7261L24.4425 20.7127L24.4462 20.7082L24.449 20.705C24.6006 20.5266 24.6838 20.3002 24.6838 20.066V12.8012L28.1969 10.4231C28.4704 10.2379 28.6338 10.0112 28.6305 9.598C28.6279 9.26775 28.4602 8.96072 28.1839 8.77997L17.5158 1.80445ZM22.7102 14.1372V19.6756C22.6315 19.7549 22.5336 19.8501 22.4172 19.9576C22.0501 20.2965 21.5026 20.7532 20.7939 21.2105C19.374 22.1264 17.3377 23.0264 14.816 23.0264C12.2942 23.0264 10.2577 22.1264 8.83792 21.2105C8.1291 20.7532 7.58158 20.2965 7.21445 19.9576C7.09798 19.8501 7.00007 19.7549 6.92132 19.6756V14.1377L12.0498 17.6092C13.7204 18.7399 15.911 18.7399 17.5815 17.609L22.7102 14.1372ZM5.93452 20.066L5.18381 20.7064C5.18381 20.7064 5.18265 20.705 5.93452 20.066ZM13.1955 3.45623C14.1798 2.81271 15.4516 2.81273 16.4358 3.45629L25.8625 9.60581L16.4752 15.9747C15.4729 16.6532 14.1585 16.6533 13.1561 15.9748L3.76826 9.62012L13.1955 3.45623Z"
			fill="#4F8AFB"
		/>
		<path
			d="M5.18381 20.7064C5.03216 20.528 4.94771 20.3002 4.94771 20.066V12.8017L2.9741 11.4658V17.6976C2.9741 18.2425 2.53228 18.6844 1.98729 18.6844C1.44229 18.6844 1.00048 18.2425 1.00048 17.6976V9.80317C1.00048 9.77059 1.00206 9.73838 1.00514 9.70662C1.00147 9.67076 0.99974 9.63451 1.00003 9.598C1.00267 9.26773 1.17035 8.9607 1.44678 8.77995L12.1155 1.80437C13.7559 0.731852 15.8755 0.731872 17.5158 1.80445L28.1839 8.77997C28.4602 8.96072 28.6279 9.26775 28.6305 9.598C28.6338 10.0112 28.4704 10.2379 28.1969 10.4231L24.6838 12.8012V20.066C24.6838 20.3002 24.6006 20.5266 24.449 20.705L24.4462 20.7082L24.4425 20.7127L24.4308 20.7261L24.3927 20.7692C24.3608 20.8047 24.3156 20.854 24.2575 20.9152C24.1415 21.0374 23.9735 21.2069 23.7559 21.4078C23.3213 21.8091 22.6846 22.3394 21.8638 22.8689C20.2245 23.9264 17.8203 25 14.816 25C11.8116 25 9.40737 23.9266 7.76798 22.8689C6.94722 22.3394 6.31051 21.8091 5.87582 21.4078C5.65815 21.2069 5.49014 21.0374 5.37407 20.9152C5.31601 20.8542 5.27083 20.8047 5.23886 20.7692L5.20079 20.7261L5.18922 20.7127L5.18531 20.7082L5.18381 20.7064ZM5.18381 20.7064L5.93452 20.066C5.18265 20.705 5.18381 20.7064 5.18381 20.7064ZM22.7102 14.1372V19.6756C22.6315 19.7549 22.5336 19.8501 22.4172 19.9576C22.0501 20.2965 21.5026 20.7532 20.7939 21.2105C19.374 22.1264 17.3377 23.0264 14.816 23.0264C12.2942 23.0264 10.2577 22.1264 8.83792 21.2105C8.1291 20.7532 7.58158 20.2965 7.21444 19.9576C7.09798 19.8501 7.00007 19.7549 6.92132 19.6756V14.1377L12.0498 17.6092C13.7204 18.7399 15.911 18.7399 17.5815 17.609L22.7102 14.1372ZM13.1955 3.45623C14.1798 2.81271 15.4516 2.81273 16.4358 3.45629L25.8625 9.60581L16.4752 15.9747C15.4729 16.6532 14.1585 16.6533 13.1561 15.9748L3.76826 9.62012L13.1955 3.45623Z"
			stroke="#4F8AFB"
			strokeWidth="0.35"
		/>
	</svg>
);

export default HatGraduation;
