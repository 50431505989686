import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Layout } from 'antd';
import PrimaryHeader from './PrimaryHeader';
import SecondaryHeader from './SecondaryHeader';
import SiteFooter from './SiteFooter';
import styles from './NavigationLayout.module.scss';
const { Content } = Layout;

const NavigationLayout = ({ children, location, isMobile }) => (
	<Layout className={cx(styles.root, { [styles.isMobile]: isMobile })}>
		<PrimaryHeader location={location} isMobile={isMobile} />
		{!isMobile && <SecondaryHeader location={location} isMobile={isMobile} />}
		<Content className={styles.siteLayout}>{children}</Content>
		{!isMobile && <SiteFooter />}
	</Layout>
);

NavigationLayout.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
	isMobile: PropTypes.bool,
};

export default NavigationLayout;
