import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CircularSpinner from '../CircularSpinner/index';
import styles from './index.module.scss';

const Input = (props) => {
	const handleOnBlur = (event) => {
		const { value } = event.target;

		if (props.onBlur) {
			props.onBlur({
				event,
				value,
			});
		}
	};

	const handleOnChange = (event) => {
		const { value } = event.target;
		const { onChange, name } = props;

		if (onChange) {
			onChange({ value, name, event });
		}
	};

	const handleKeyPress = (event) => {
		const { name, onEnter } = props;

		if (event.key === 'Enter' && onEnter) {
			onEnter({
				name,
				event,
				value: props.value,
			});
		}
	};

	const handleFocus = (event) => {
		const { name, onFocus, value } = props;
		const newEvent = { ...event };
		// To fix the issue with cursor at beginning
		if (value) {
			newEvent.target.value = '';
			newEvent.target.value = value;
		}

		if (onFocus) {
			onFocus({
				event: newEvent,
				name,
			});
		}
	};

	const handleKeyDown = (event) => {
		const { name, onKeyDown } = props;
		// prevent e,+ etc., for numerical ip
		if (
			((event.which !== 190 &&
				event.which !== 8 &&
				event.which !== 0 &&
				event.which < 48) ||
				(event.which > 57 && event.which < 190)) &&
			type === 'number'
		) {
			event.preventDefault();
		} else if (onKeyDown) {
			onKeyDown({
				event,
				name,
			});
		}
	};

	const {
		className,
		inputClassName,
		labelClassName,
		type,
		label,
		placeholder,
		defaultValue,
		readOnly,
		multi,
		maxLength,
		autoFocus,
		value,
		accept,
		error,
		onEnter,
		disabled,
		enableResize,
		loading,
		children,
	} = props;

	const cs = cx(styles.container, className);

	const inputClassNames = cx(
		styles.input,
		{
			multi,
			enableResize: multi && enableResize,
			readonly: readOnly,
			loading,
			error,
			icon: children,
			disabled: disabled && (!readOnly || !loading),
		},
		inputClassName,
	);

	const labelClassNames = cx(styles.label, labelClassName, {
		[styles.labelError]: error,
	});
	const helperTextClassName = cx(styles.helperText, {
		[styles.helperError]: error,
	});

	const genProps = {
		autoFocus,
		placeholder,
		value,
		defaultValue,
		readOnly,
		maxLength,
		className: inputClassNames,
		onChange: handleOnChange,
		onFocus: handleFocus,
		onBlur: handleOnBlur,
		onKeyDown: handleKeyDown,
		name: props.name,
		disabled: disabled || readOnly || loading,
	};

	if (onEnter) genProps.onKeyPress = handleKeyPress;

	// safari support
	if (type === 'number') {
		genProps.inputMode = 'numeric';
		genProps.pattern = '[0-9]*';
	}

	return (
		<div className={cs}>
			{label ? <label className={labelClassNames}>{label}</label> : null}

			{multi ? (
				<textarea {...genProps}></textarea>
			) : (
				<input {...genProps} type={type} accept={accept} />
			)}

			{children && <span className={styles.icon}>{children}</span>}

			{loading && (
				<span className="spinner">
					<CircularSpinner />
				</span>
			)}

			{error && error.length ? (
				<label className={helperTextClassName}>{error}</label>
			) : null}
		</div>
	);
};

Input.propTypes = {
	name: PropTypes.string,
	accept: PropTypes.string,
	type: PropTypes.oneOf(['text', 'number', 'password', 'file', 'date', 'email']),
	label: PropTypes.string,
	placeholder: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	readOnly: PropTypes.bool,
	loading: PropTypes.bool,
	enableResize: PropTypes.bool,
	autoFocus: PropTypes.bool,
	required: PropTypes.bool,
	maxLength: PropTypes.number,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	/* Will be applied to container */
	className: PropTypes.string,
	/* Will be applied to underlying input/textarea tag */
	inputClassName: PropTypes.string,
	/* Will be applied to label */
	labelClassName: PropTypes.string,
	/* Renders a textarea if true */
	multi: PropTypes.bool,
	/* Value */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.string,
	disabled: PropTypes.bool,
	onEnter: PropTypes.func,
	children: PropTypes.array,
};

Input.defaultProps = {
	className: '',
	inputClassName: '',
	labelClassName: '',
	type: 'text',
	label: '',
	placeholder: '',
	readOnly: false,
	multi: false,
	disabled: false,
};

// eslint-disable-next-line react/display-name
export default Input;
