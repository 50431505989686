import moment from 'moment-timezone';

import convertToLocalTimezone from './convertToLocalTimezone';

/**
 * Formats a list of time slots to a local timezone.
 *
 * This function takes an array of time slots, each with an `availableDate`, `timeStart`, and `timeEnd` property.
 * It processes the slots to handle any blocked time periods, and then maps the slots to a new array with the times
 * converted to the local timezone.
 *
 * @param {Object[]} slots - An array of time slot objects.
 * @param {string} slots[].availableDate - The date of the time slot.
 * @param {string} slots[].timeStart - The start time of the time slot.
 * @param {string} slots[].timeEnd - The end time of the time slot.
 * @param {Object[]} slots[].blockedTimeList - A list of blocked time periods within the slot.
 * @param {boolean} slots[].blockedTimeList[].escape - Indicates whether the blocked time period should be treated as a separate slot.
 * @returns {Object[]} - An array of mapped time slot objects with the times in the local timezone.
 */
export default function formatSlotsToLocal(slots) {
	const localMappedSlots = [];
	const normalizedSlots = [];

	// Normalize the slots by splitting them into separate slots if there are blocked times
	for (let i = 0; i < slots.length; i++) {
		const slot = slots[i];

		const systemBlockedTimeList = slot.blockedTimeList.filter(
			(e) => e.systemGenerated === true,
		);

		// If there are blocked times, split the slot into two slots
		if (systemBlockedTimeList.length > 0) {
			normalizedSlots.push({
				...slot,
				timeEnd: systemBlockedTimeList[0].timeStart,
			});
			normalizedSlots.push({
				...slot,
				timeStart: systemBlockedTimeList[0].timeEnd,
			});
		} else {
			normalizedSlots.push({
				...slot,
			});
		}
	}

	// Convert the normalized slots to local timezone
	for (let j = 0; j < normalizedSlots.length; j++) {
		const slot = normalizedSlots[j];
		const nextSlot = normalizedSlots[j + 1];

		const currentSlotBlockedTimeList = slot.blockedTimeList.filter(
			(e) => !e.systemGenerated,
		);

		const nextSlotBlockedTimeList = nextSlot?.blockedTimeList.filter(
			(e) => !e.systemGenerated,
		);

		// If the slot ends at 23:59:59, it means it spans across two days
		if (slot.timeEnd === '23:59:59') {
			const blockedTimeList = currentSlotBlockedTimeList
				.filter((e) => e.timeEnd !== '23:59:59')
				.map((blockedTime) => {
					const { timeStart: blockedTimeTimeStart, timeEnd: blockedTimeTimeEnd } =
						blockedTime;
					const blockedTimeLocalStartDateTime = convertToLocalTimezone(
						`${slot.availableDate} ${blockedTimeTimeStart}`,
					);
					const blockedTimeLocalEndDateTime = convertToLocalTimezone(
						`${slot.availableDate} ${blockedTimeTimeEnd}`,
					);

					return {
						timeStart: blockedTimeLocalStartDateTime.format('HH:mm:ss'),
						timeEnd: blockedTimeLocalEndDateTime.format('HH:mm:ss'),
					};
				});

			const currentSlotBlockedTime = currentSlotBlockedTimeList.find(
				(s) => s.timeEnd === '23:59:59',
			);
			const nextSlotBlockedTimeIndex = nextSlotBlockedTimeList.findIndex(
				(s) => s.timeStart === '00:00:00',
			);

			const nextSlotBlockedTime =
				nextSlotBlockedTimeList[nextSlotBlockedTimeIndex];

			delete nextSlot?.blockedTimeList[nextSlotBlockedTimeIndex];

			if (currentSlotBlockedTime && nextSlotBlockedTime) {
				const blockedTimeLocalStartDateTime = convertToLocalTimezone(
					`${slot.availableDate} ${currentSlotBlockedTime.timeStart}`,
				);
				const blockedTimeLocalEndDateTime = convertToLocalTimezone(
					`${nextSlot.availableDate} ${nextSlotBlockedTime.timeEnd}`,
				);

				blockedTimeList.push({
					s: 1,
					timeStart: blockedTimeLocalStartDateTime.format('HH:mm:ss'),
					timeEnd: blockedTimeLocalEndDateTime.format('HH:mm:ss'),
					systemGenerated: false,
				});
			}

			localMappedSlots.push({
				...slot,
				blockedTimeList,
				availableDate: slot.availableDate,
				timeStart: convertToLocalTimezone(
					`${slot.availableDate} ${slot.timeStart}`,
				).format('HH:mm:ss'),
				timeEnd: convertToLocalTimezone(
					`${slot.availableDate} ${nextSlot.timeEnd}`,
				).format('HH:mm:ss'),
			});

			j++;
		} else {
			const blockedTimeList = currentSlotBlockedTimeList.map((blockedTime) => {
				const { timeStart: blockedTimeTimeStart, timeEnd: blockedTimeTimeEnd } =
					blockedTime;
				const blockedTimeLocalStartDateTime = convertToLocalTimezone(
					`${slot.availableDate} ${blockedTimeTimeStart}`,
				);
				const blockedTimeLocalEndDateTime = convertToLocalTimezone(
					`${slot.availableDate} ${blockedTimeTimeEnd}`,
				);

				return {
					timeStart: blockedTimeLocalStartDateTime.format('HH:mm:ss'),
					timeEnd: blockedTimeLocalEndDateTime.format('HH:mm:ss'),
				};
			});
			const localStartDateTime = convertToLocalTimezone(
				`${slot.availableDate} ${slot.timeStart}`,
			);

			const localEndDateTime = convertToLocalTimezone(
				`${slot.availableDate} ${slot.timeEnd}`,
			);

			if (
				moment(localEndDateTime.format('YYYY-MM-DD')).diff(
					moment(localStartDateTime.format('YYYY-MM-DD')),
					'd',
				)
			) {
				localMappedSlots.push({
					...slot,
					availableDate: localStartDateTime.format('YYYY-MM-DD'),
					timeStart: localStartDateTime.format('HH:mm:ss'),
					timeEnd: '23:59:59',
					blockedTimeList: [],
				});

				localMappedSlots.push({
					...slot,
					availableDate: localEndDateTime.format('YYYY-MM-DD'),
					timeStart: '00:00:00',
					timeEnd: localEndDateTime.format('HH:mm:ss'),
					blockedTimeList,
				});
			} else {
				localMappedSlots.push({
					...slot,
					blockedTimeList: currentSlotBlockedTimeList.map((blockedTime) => {
						const { timeStart: blockedTimeTimeStart, timeEnd: blockedTimeTimeEnd } =
							blockedTime;
						const blockedTimeLocalStartDateTime = convertToLocalTimezone(
							`${slot.availableDate} ${blockedTimeTimeStart}`,
						);
						const blockedTimeLocalEndDateTime = convertToLocalTimezone(
							`${slot.availableDate} ${blockedTimeTimeEnd}`,
						);

						return {
							timeStart: blockedTimeLocalStartDateTime.format('HH:mm:ss'),
							timeEnd: blockedTimeLocalEndDateTime.format('HH:mm:ss'),
						};
					}),
					availableDate: convertToLocalTimezone(
						`${slot.availableDate} ${slot.timeStart}`,
					).format('YYYY-MM-DD'),
					timeStart: convertToLocalTimezone(
						`${slot.availableDate} ${slot.timeStart}`,
					).format('HH:mm:ss'),
					timeEnd: convertToLocalTimezone(
						`${slot.availableDate} ${slot.timeEnd}`,
					).format('HH:mm:ss'),
				});
			}

			// Convert the slot to local timezone
		}
	}

	return localMappedSlots;
}
