/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setMeetingEvents,
	setAllEventTypes,
	setMeetingEventsByType,
	setMeetingExpertsByType,
	setFilteredLearnerGroup,
	setExpertTimeSlots,
	setMeetingMessages,
	setReasons,
	setOpenWindow,
} from '../actions/meetingEvents';

const initialState = {
	allEvents: { data: [], headers: {} },
	eventsByType: [],
	expertsByType: [],
	eventTypes: [],
	filteredLearnerGroup: {},
	expertTimeSlots: [],
	openWindowMsg: {},
	reasons: [],
	messages: {
		getMeetingsError: {},
		getMeetingsByTypeError: {},
		getExpertsByTypeError: {},
		getFilteredLearnerGroupError: {},
		getExpertSlotError: {},
		createMeetingError: {},
		createMeetingSuccess: {},
		getAllEventTypesError: {},
		getOpenSlotError: {},
		getReasonsError: {},
	},
};

const meetingEventsReducer = createReducer(initialState, {
	[setMeetingEvents]: (state, action) => {
		state.allEvents = action.payload;
	},
	[setMeetingEventsByType]: (state, action) => {
		state.eventsByType = action.payload;
	},
	[setAllEventTypes]: (state, action) => {
		state.eventTypes = action.payload;
	},
	[setMeetingExpertsByType]: (state, action) => {
		state.expertsByType = action.payload;
	},
	[setFilteredLearnerGroup]: (state, action) => {
		state.filteredLearnerGroup = action.payload;
	},
	[setExpertTimeSlots]: (state, action) => {
		state.expertTimeSlots = action.payload;
	},
	[setReasons]: (state, action) => {
		state.reasons = action.payload;
	},
	[setOpenWindow]: (state, action) => {
		state.openWindowMsg = action.payload;
	},
	[setMeetingMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default meetingEventsReducer;
