import {
	getExpertEventMappingList,
	getExpertMappingUploadHistory,
	getExpertIndustryDomains,
	getExpertCoreSkills,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setExpertMappingList = createAction(
	'SET_EXPERT_MAPPING_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setExpertUploadList = createAction(
	'SET_EXPERT_UPLOAD_LIST',
	(data) => ({
		payload: data,
	}),
);

export const setIndustryDomains = createAction(
	'SET_INDUSTRY_DOMAINS',
	(data) => ({
		payload: data,
	}),
);

export const setCoreSkills = createAction('SET_CORE_SKILLS', (data) => ({
	payload: data,
}));

export const setExpertMappingMessages = createAction(
	'SET_EXPERT_MAPPING_MESSAGES',
	(data) => ({ payload: data }),
);

export const getExpertList = (query) => (dispatch) => {
	getExpertEventMappingList(query)
		.then((response) => {
			dispatch(setExpertMappingList(response));
		})
		.catch((err) => {
			dispatch(setExpertMappingMessages({ getExpertListError: err.response }));
		});
};

export const getExpertUploadList = (query) => (dispatch) => {
	getExpertMappingUploadHistory(query)
		.then((response) => {
			dispatch(setExpertUploadList(response));
		})
		.catch((err) => {
			dispatch(setExpertMappingMessages({ getExpertUploadError: err.response }));
		});
};

export const getIndustryDomains = () => (dispatch) => {
	getExpertIndustryDomains()
		.then((response) => {
			dispatch(setIndustryDomains(response.data));
		})
		.catch((err) => {
			dispatch(
				setExpertMappingMessages({ getIndustryDomainsError: err.response }),
			);
		});
};

export const getCoreSkills = () => (dispatch) => {
	getExpertCoreSkills()
		.then((response) => {
			dispatch(setCoreSkills(response.data));
		})
		.catch((err) => {
			dispatch(setExpertMappingMessages({ getCoreSkillError: err.response }));
		});
};
