import { features } from '../../accessControl/features';
import routePaths from '../../routePaths';
import { crmNavs, userNavs } from '../SecondaryHeader/constants/navOptions';
const primaryNavs = [
	{
		name: 'Home',
		key: 'home',
		icon: '',
		path: routePaths.homePage,
		features: [features.HOME],
		isVisibleOnHome: false,
		isVisibleInNav: true,
	},
	{
		name: 'CRM',
		key: 'crm',
		icon: '',
		path: routePaths.calendarManagement__calendarEventListView,
		subMenu: crmNavs,
		isVisibleOnHome: true,
		isVisibleInNav: true,
	},
	{
		name: 'Experts',
		key: 'experts',
		icon: '',
		path: routePaths.userManagement__userListView,
		subMenu: userNavs,
		isVisibleOnHome: true,
		isVisibleInNav: true,
	},
];
export default primaryNavs;
