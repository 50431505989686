import { constants } from 'utils/constants';

const setCookie = (obj) => {
	Object.keys(obj).forEach((key) => {
		const domainString =
			constants('env') === 'local' ? '' : `domain=${constants('cookieDomain')}`;
		document.cookie = `${key}=${obj[key]}; path=/; ${domainString}`;
	});
};

const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(';').shift();
	}
	return '';
};

const deleteCookie = (keys) => {
	keys.forEach((k) => {
		const domainString =
			constants('env') === 'local' ? '' : `domain=${constants('cookieDomain')}`;
		document.cookie = `${k}=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domainString}`;
	});
};

export { setCookie, getCookie, deleteCookie };
