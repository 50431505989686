import BulletListSquared from '../../../assets/icons/BulletListSquared';
import Comment from '../../../assets/icons/Comment';
import DeviceMeetingRoom from '../../../assets/icons/DeviceMeetingRoom';
import DeviceMeetingRoomRemote from '../../../assets/icons/DeviceMeetingRoomRemote';
import DocumentEdit from '../../../assets/icons/DocumentEdit';
import Edit from '../../../assets/icons/Edit';
import LinkSquare from '../../../assets/icons/LinkSquare';
import PersonAvailable from '../../../assets/icons/PersonAvailable';
import Send from '../../../assets/icons/Send';
import HatGraduation from '../../../assets/icons/HatGraduation';
import SymbolsCategoryOutlinedRounded from '../../../assets/icons/SymbolsCategoryOutlinedRounded';
import routePaths from '../../../routePaths';
import { features } from '../../../accessControl/features';

// Nav for CRM
export const crmNavs = [
	{
		name: 'Meeting',
		description: 'Add and view meetings',
		svgIcon: DeviceMeetingRoom,
		key: 'meeting',
		path: routePaths.calendarManagement__calendarEventListView,
		mPath: routePaths.calendarManagement__calendarEventCalendarView,
		features: [features.CALENDAR_EVENT_LIST_VIEW, features.ADD_CALENDAR_EVENT],
	},
	{
		name: 'Availability',
		description: 'Allocate and view availability',
		svgIcon: PersonAvailable,
		key: 'availability',
		path: routePaths.availabilityManagement__availabilityListView,
		features: [features.AVAILABILITY_LIST_VIEW],
	},
	{
		name: 'Event',
		description: 'Add and view event templates',
		svgIcon: Edit,
		key: 'event',
		path: routePaths.meetingManagement__meetingEventListView,
		features: [features.MEETING_EVENT_LIST_VIEW],
	},
	{
		name: 'Mapping',
		description: 'Add and map experts/learners to groups',
		svgIcon: LinkSquare,
		key: 'mapping',
		path: routePaths.mappingManagement__expertManagementView,
		features: [
			features.LEARNER_MANAGEMENT_VIEW,
			features.EXPERT_MANAGEMENT_VIEW,
			features.GROUP_MANAGEMENT_VIEW,
			features.EXPERT_GROUP_MANAGEMENT_VIEW,
			features.LEARNER_GROUP_MANAGEMENT_VIEW,
		],
	},
	{
		name: 'Feedback Form',
		description: 'Add and view feedback forms',
		svgIcon: DocumentEdit,
		key: 'feedbackForm',
		path: routePaths.feedbackFormManagement__feedbackFormListView,
		features: [features.FEEDBACK_FORM_LIST_VIEW],
	},
	{
		name: 'Communication',
		description: 'Manage communication and reminders',
		svgIcon: Comment,
		key: 'communication',
		path: routePaths.communicationManagement__feedbackReminderView,
		features: [
			features.FEEDBACK_REMINDER_VIEW,
			features.MEETING_REMINDER_VIEW,
			features.AVAILABILITY_REMINDER_VIEW,
		],
	},
	{
		name: 'Request',
		description: 'Manage cancellation and rescheduling requests',
		svgIcon: Send,
		key: 'request',
		path: routePaths.requestManagement__requestListView,
		features: [features.REQUEST_LIST_VIEW],
	},
	{
		name: 'Meeting Window',
		description: 'Mentorship and Career Coaching Management',
		svgIcon: DeviceMeetingRoomRemote,
		key: 'meetingWindow',
		path: routePaths.meetingWindow__meetingWindowListView,
		features: [features.MEETING_WINDOW],
	},
	{
		name: 'Category',
		description: 'Manage categories and pricing',
		svgIcon: SymbolsCategoryOutlinedRounded,
		key: 'category',
		path: routePaths.categoryManagement__categoryListView,
		features: [features.CATEGORY_LIST_VIEW],
	},
	{
		name: 'Student List',
		description: 'Manage student and view profile',
		svgIcon: BulletListSquared,
		key: 'student',
		path: routePaths.studentList_studentListView,
		features: [features.STUDENT_LIST_VIEW],
	},
];

// Nav for Users
export const userNavs = [
	{
		name: 'Expert Registration',
		description: 'Add & view experts',
		svgIcon: HatGraduation,
		key: 'expert',
		path: routePaths.userManagement__userListView,
		features: [features.USER_LIST_VIEW, features.BULK_ADD_USER],
	},
];
