import formBuilderApis from './axiosInstances/formBuilder';

// Form Builder APIs
export const getFormList = (query) => {
	const url = `/forms${query}`;
	return formBuilderApis.get(url);
};

export const getFormDetails = (id) => {
	const url = `/forms/${id}`;
	return formBuilderApis.get(url);
};

export const updateForm = (payload) => {
	const url = `/forms`;
	return formBuilderApis.post(url, payload);
};

export const submitFormAnswer = (payload) => {
	const url = `/forms/submit`;
	return formBuilderApis.post(url, payload);
};
