/* eslint-disable */
import moment from 'moment-timezone';
import _, { cloneDeep } from 'lodash';

import convertToIST from './convertToIST';

/**
 * Formats the provided slots to the IST (Indian Standard Time) timezone.
 *
 * This function takes an array of slot objects, each containing information about
 * an available time slot, and converts the start and end times of the slots and
 * any blocked time periods within the slots to the IST timezone.
 *
 * @param {Object[]} slots - An array of slot objects, each containing the following properties:
 *   - `availableDate`: The date the slot is available.
 *   - `timeStart`: The start time of the slot.
 *   - `timeEnd`: The end time of the slot.
 *   - `blockedTimeList`: An array of objects representing blocked time periods within the slot, each with `timeStart` and `timeEnd` properties.
 * @returns {Object[]} - An array of formatted slot objects, with the start and end times and blocked time periods converted to the IST timezone.
 */
export default function formatSlotsToISTTimezone(slots) {
	// Initialize an empty array to store the mapped slots
	const mappedSlots = [];

	const sortedSlots = _.sortBy(slots, 'availableDate');

	// Iterate over each slot in the input slots array
	sortedSlots.forEach((slot, i) => {
		const clonedSlot = cloneDeep(slot);
		const clonedNextSlot = cloneDeep(sortedSlots[i + 1]);
		let { availableDate, timeStart, timeEnd, blockedTimeList } = clonedSlot;
		// Convert the timeStart and timeEnd to IST timezone
		const localStartDateTime = convertToIST(`${availableDate} ${timeStart}`);
		const localEndDateTime = convertToIST(`${availableDate} ${timeEnd}`);

		// If there are blocked time slots, convert them to IST timezone

		// Check if the slot spans across multiple days
		if (
			moment(localEndDateTime.format('YYYY-MM-DD')).diff(
				moment(localStartDateTime.format('YYYY-MM-DD')),
				'd',
			)
		) {
			const currentSlotBlockTimeList = [];
			const nextSlotBlockTimeList = [];

			for (let i = 0; i < clonedSlot.blockedTimeList.length; i++) {
				const blockedTime = clonedSlot.blockedTimeList[i];

				const { timeStart: blockedTimeTimeStart, timeEnd: blockedTimeTimeEnd } =
					blockedTime;

				const blockedTimeLocalStartDateTime = convertToIST(
					`${availableDate} ${blockedTimeTimeStart}`,
				);
				const blockedTimeLocalEndDateTime = convertToIST(
					`${availableDate} ${blockedTimeTimeEnd}`,
				);
				if (
					moment(blockedTimeLocalEndDateTime.format('YYYY-MM-DD')).diff(
						moment(blockedTimeLocalStartDateTime.format('YYYY-MM-DD')),
						'd',
					)
				) {
					currentSlotBlockTimeList.push({
						timeStart: blockedTimeLocalStartDateTime.format('HH:mm:ss'),
						timeEnd: '23:59:59',
					});
					nextSlotBlockTimeList.push({
						timeStart: '00:00:00',
						timeEnd: blockedTimeLocalEndDateTime.format('HH:mm:ss'),
					});
				} else {
					currentSlotBlockTimeList.push({
						timeStart: blockedTimeLocalStartDateTime.format('HH:mm:ss'),
						timeEnd: blockedTimeLocalEndDateTime.format('HH:mm:ss'),
					});
				}
			}

			// If the slot spans across multiple days, split it into two slots
			mappedSlots.push({
				...clonedSlot,
				availableDate: localStartDateTime.format('YYYY-MM-DD'),
				timeStart: localStartDateTime.format('HH:mm:ss'),
				timeEnd: '23:59:59',
				blockedTimeList: currentSlotBlockTimeList,
			});

			mappedSlots.push({
				...clonedSlot,
				availableDate: localEndDateTime.format('YYYY-MM-DD'),
				timeStart: '00:00:00',
				timeEnd: localEndDateTime.format('HH:mm:ss'),
				blockedTimeList: nextSlotBlockTimeList,
			});
		} else {
			const currentSlotBlockedTimeList = clonedSlot.blockedTimeList.map(
				(blockedTime) => {
					const { timeStart: blockedTimeTimeStart, timeEnd: blockedTimeTimeEnd } =
						blockedTime;

					const blockedTimeLocalStartDateTime = convertToIST(
						`${availableDate} ${blockedTimeTimeStart}`,
					);
					const blockedTimeLocalEndDateTime = convertToIST(
						`${availableDate} ${blockedTimeTimeEnd}`,
					);

					return {
						timeStart: blockedTimeLocalStartDateTime.format('HH:mm:ss'),
						timeEnd: blockedTimeLocalEndDateTime.format('HH:mm:ss'),
					};
				},
			);

			// If the slot is within a single day, add it to the mappedSlots array
			mappedSlots.push({
				...clonedSlot,
				availableDate: localStartDateTime.format('YYYY-MM-DD'),
				timeStart: localStartDateTime.format('HH:mm:ss'),
				timeEnd: localEndDateTime.format('HH:mm:ss'),
				blockedTimeList: currentSlotBlockedTimeList,
			});
		}
	});

	const cleanedMappedSlotsForRemoval = [];

	for (let i = 0; i < mappedSlots.length; i++) {
		const slot = mappedSlots[i];

		if (slot.toBeRemoved) {
			const dateExists = mappedSlots.findIndex(
				(s) => s.availableDate === slot.availableDate && !s.toBeRemoved,
			);
			if (dateExists === -1) {
				cleanedMappedSlotsForRemoval.push(slot);
			}
		} else {
			cleanedMappedSlotsForRemoval.push(slot);
		}
	}

	// Group the mapped slots by availableDate
	const groupedByDate = _.groupBy(
		_.clone(cleanedMappedSlotsForRemoval),
		'availableDate',
	);

	// Merge the slots that span across midnight
	for (let [date, group] of Object.entries(groupedByDate)) {
		if (group.length === 2 && group[0].timeStart === '00:00:00') {
			const [firstPart, secondPart] = group;
			// Add a blocked time slot between the end of the first part and the start of the second part
			const btl = [
				{
					timeStart: firstPart.timeEnd,
					timeEnd: secondPart.timeStart,
					systemGenerated: true,
				},
			];

			secondPart.blockedTimeList = [
				...firstPart.blockedTimeList,
				...btl,
				...secondPart.blockedTimeList,
			];
			// Merge the two parts by updating the timeStart of the second part
			secondPart.timeStart = firstPart.timeStart;
			// Remove the first part from the group
			delete group[0];
		} else if (group.length === 2) {
			const [firstPart, secondPart] = group;
			secondPart.timeStart = firstPart.timeStart;
			delete group[0];
		}
	}
	// Flatten the grouped slots and remove any undefined values
	const flattenedGroupedByDate = _.flatten(
		_.compact(_.values(groupedByDate)),
	).filter((e) => e);

	// clean date split toBeRemoved

	const cleanedFlattenedGroupedByDate = [];
	for (let j = 0; j < flattenedGroupedByDate.length; j++) {
		const slot = flattenedGroupedByDate[j];
		const nextDate = moment(slot.availableDate, 'YYYY-MM-DD').add(1, 'd');
		if (
			slot.toBeRemoved &&
			slot.timeEnd === '23:59:59' &&
			!flattenedGroupedByDate.find(
				(s) => s.availableDate === nextDate.format('YYYY-MM-DD'),
			)
		) {
			cleanedFlattenedGroupedByDate.push(slot);
			cleanedFlattenedGroupedByDate.push({
				...slot,
				availableDate: nextDate.format('YYYY-MM-DD'),
				toBeRemoved: true,
			});
		} else {
			cleanedFlattenedGroupedByDate.push(slot);
		}
	}

	return cleanedFlattenedGroupedByDate;
}
