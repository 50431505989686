import { getRequests, getRequestCounts } from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setRequests = createAction('SET_REQUESTS', (data) => ({
	payload: data,
}));

export const setRequestCounts = createAction('SET_REQUEST_COUNTS', (data) => ({
	payload: data,
}));

export const setRequestMessages = createAction(
	'SET_REQUEST_MESSAGES',
	(data) => ({
		payload: data,
	}),
);

export const getRequestList = (query) => (dispatch) => {
	getRequests(query)
		.then((response) => {
			dispatch(setRequests(response));
		})
		.catch((err) => {
			dispatch(setRequestMessages({ getRequestError: err.response }));
		});
};

export const getRequestHeadCounts = (query) => (dispatch) => {
	getRequestCounts(query)
		.then((response) => {
			dispatch(setRequestCounts(response.data));
		})
		.catch((err) => {
			dispatch(setRequestMessages({ getRequestCountError: err.response }));
		});
};
