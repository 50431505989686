import React from 'react';

const Send = (props) => (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0.967416 6.6957L26.3785 5.02213C26.7884 4.99513 27.1426 5.30556 27.1696 5.7155C27.1804 5.87906 27.1368 6.04159 27.0457 6.17785L12.8911 27.3472C12.6628 27.6888 12.2008 27.7805 11.8593 27.5521C11.71 27.4523 11.6018 27.3018 11.5548 27.1284L8.56164 16.0863L0.488654 7.96229C0.199071 7.67088 0.200554 7.19989 0.491966 6.91031C0.619349 6.78372 0.788223 6.7075 0.967416 6.6957ZM2.69587 8.07282L9.29336 14.7121L9.36428 14.6505L9.44426 14.5973L18.4632 9.39022C18.819 9.18481 19.2739 9.30671 19.4793 9.6625C19.6619 9.97875 19.5859 10.3734 19.3169 10.6014L19.207 10.6786L10.1881 15.8857C10.1477 15.9091 10.1059 15.9282 10.0634 15.9432L12.563 25.1614L24.9699 6.60585L2.69587 8.07282Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.5"
		/>
	</svg>
);
export default Send;
