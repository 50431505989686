import authApi from './axiosInstances/auth';
import centralApi from './axiosInstances/central';

export function loginUser({ email, password }) {
	const url = `/apis/auth/v5/login`;
	return authApi.post(url, { username: email, password });
}

export function authenticateUser() {
	const url = `/apis/v2/authenticate/`;
	return centralApi.get(url);
}

export function getUserDetails() {
	const url = `/apis/v2/users/`;
	return authApi.get(url);
}

export function logoutUser() {
	const url = `/apis/logout`;
	return authApi.post(url);
}
