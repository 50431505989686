import { getCategories, getCategoryName } from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setCategories = createAction('SET_CATEGORIES', (data) => ({
	payload: data,
}));

export const setCategoryName = createAction('SET_CATEGORY_NAME', (data) => ({
	payload: data,
}));

export const setCategoriesMessages = createAction(
	'SET_CATEGORY_MESSAGES',
	(data) => ({ payload: data }),
);

export const getAllCategories = (query) => (dispatch) => {
	getCategories(query)
		.then((response) => {
			dispatch(setCategories(response));
		})
		.catch((err) => {
			dispatch(setCategoriesMessages({ getCategoriesError: err.response }));
		});
};

export const getCategoryNames = () => (dispatch) => {
	getCategoryName()
		.then((response) => {
			dispatch(setCategoryName(response.data));
		})
		.catch((err) => {
			dispatch(setCategoriesMessages({ getCategoryNameError: err.response }));
		});
};
