/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setUserList,
	setConsumerMessages,
	setUserProfile,
} from '../actions/consumer';

const initialState = {
	users: { data: [], headers: {} },
	userProfile: {},
	messages: {
		uploadError: {},
		getHistoryError: {},
		getUsersError: {},
		getUserProfileError: {},
	},
};

const consumerReducer = createReducer(initialState, {
	[setUserList]: (state, action) => {
		state.users = action.payload;
	},
	[setUserProfile]: (state, action) => {
		state.userProfile = action.payload;
	},
	[setConsumerMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default consumerReducer;
