import { bulkUploadAlumni, getAlumniList, getCurrentDomains } from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setAlumniList = createAction('SET_ALUMNI_LIST', (data) => ({
	payload: data,
}));

export const setCurrentDomains = createAction('SET_DOMAIN_LIST', (data) => ({
	payload: data,
}));

export const setAlumniMessages = createAction(
	'SET_ALUMNI_MESSAGES',
	(data) => ({ payload: data }),
);

export const uploadAlumni = (payload) => (dispatch) => {
	bulkUploadAlumni(payload).catch((err) => {
		dispatch(setAlumniMessages({ alumniUploadError: err.response }));
	});
};

export const getAlumniData = (query) => (dispatch) => {
	getAlumniList(query)
		.then((response) => {
			dispatch(setAlumniList(response));
		})
		.catch((err) => {
			dispatch(setAlumniMessages({ getAlumniDataError: err.response }));
		});
};

export const getDomains = () => (dispatch) => {
	getCurrentDomains()
		.then((response) => {
			dispatch(setCurrentDomains(response.data));
		})
		.catch((err) => {
			dispatch(setAlumniMessages({ getDomainsError: err.response }));
		});
};
