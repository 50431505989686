import React from 'react';

const Edit = (props) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M22.8329 1.16706C24.389 2.72314 24.389 5.24605 22.8329 6.80213L8.11692 21.5181C7.47859 22.1565 6.67878 22.6093 5.80301 22.8283L1.23866 23.9694C0.509097 24.1517 -0.151741 23.4909 0.0306491 22.7613L1.17174 18.197C1.39068 17.3212 1.84352 16.5214 2.48185 15.8831L17.1979 1.16706C18.754 -0.38902 21.2769 -0.38902 22.8329 1.16706ZM15.789 5.39315L3.89062 17.2918C3.50762 17.6748 3.23592 18.1547 3.10455 18.6802L2.36613 21.6339L5.3198 20.8955C5.84527 20.7641 6.32515 20.4924 6.70815 20.1094L18.6061 8.21026L15.789 5.39315ZM18.6066 2.57583L17.1975 3.98459L20.0146 6.8017L21.4242 5.39336C22.2022 4.61532 22.2022 3.35387 21.4242 2.57583C20.6461 1.79779 19.3847 1.79779 18.6066 2.57583Z"
			fill="#4F8AFB"
		/>
	</svg>
);

export default Edit;
