import { push } from 'connected-react-router';

export function redirect(route) {
	return (dispatch) => {
		dispatch(
			push({
				pathname: route,
			}),
		);
	};
}
