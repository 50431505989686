import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { reduxBatch } from '@manaflair/redux-batch';
import globalReducer from './index.reducer';
import userReducer from '../reducers/user.reducer';
import consumerReducer from '../reducers/consumer.reducer';
import availabilityReducer from '../reducers/availability.reducer';
import calendarEventsReducer from '../reducers/calendarEvents.reducer';
import meetingEventsReducer from '../reducers/meetingEvents.reducer';
import expertMappingReducer from '../reducers/expertMapping.reducer';
import learnerMappingReducer from '../reducers/learnerMapping.reducer';
import groupMappingReducer from '../reducers/groupMapping.reducer';
import expertGroupMappingReducer from '../reducers/expertGroupMapping.reducer';
import learnerGroupMappingReducer from '../reducers/learnerGroupMapping.reducer';
import feedbackFormReducer from '../reducers/feedbackForm.reducer';
import feedbackReducer from '../reducers/feedback.reducer';
import notificationsReducer from '../reducers/notifications.reducer';
import communicationReducer from '../reducers/communication.reducer';
import requestsReducer from '../reducers/requests.reducer';
import categoryReducer from '../reducers/category.reducer';
import meetingWindowReducer from '../reducers/meetingWindow.reducer';
import alumniReducer from '../reducers/alumni.reducer';

const history = createBrowserHistory();
history.listen(() => {
	window.scrollTo(0, 0);
});

const appReducer = combineReducers({
	router: connectRouter(history),
	global: globalReducer,
	user: userReducer,
	consumer: consumerReducer,
	availability: availabilityReducer,
	calendarEvent: calendarEventsReducer,
	meetingEvent: meetingEventsReducer,
	expertMapping: expertMappingReducer,
	learnerMapping: learnerMappingReducer,
	groupMapping: groupMappingReducer,
	expertGroupMapping: expertGroupMappingReducer,
	learnerGroupMapping: learnerGroupMappingReducer,
	feedbackForm: feedbackFormReducer,
	notification: notificationsReducer,
	communication: communicationReducer,
	request: requestsReducer,
	category: categoryReducer,
	meetingWindow: meetingWindowReducer,
	alumni: alumniReducer,
	feedback: feedbackReducer,
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_USER') {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: [
		...getDefaultMiddleware({ serializableCheck: false }),
		routerMiddleware(history),
	],
	devTools: process.env.NODE_ENV !== 'production',
	enhancers: [reduxBatch],
});

export { store, history };
