/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setScheduleTypes,
	setUserAvailability,
	setAvailabilityMessages,
} from '../actions/availability';

const initialState = {
	scheduleTypes: [],
	userAvailability: [],
	messages: {
		getScheduleTypesError: {},
		createAvailabilityError: {},
		createAvailabilitySuccess: {},
		getAvailabilityError: {},
	},
};

const availabilityReducer = createReducer(initialState, {
	[setScheduleTypes]: (state, action) => {
		state.scheduleTypes = action.payload;
	},
	[setUserAvailability]: (state, action) => {
		state.userAvailability = action.payload;
	},
	[setAvailabilityMessages]: (state, action) => {
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default availabilityReducer;
