import React from 'react';
import { history } from 'store';
import PropTypes from 'prop-types';
import rollbar from 'utils/rollbar';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errorInfo: null,
		};
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			errorInfo,
		});
		rollbar.error('Error with Test Platform Client', {
			error,
			errorInfo,
		});
		// You can also log error messages to an error reporting service here
	}

	render() {
		if (this.state.errorInfo) {
			history.push('/error/500');
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
	children: PropTypes.any,
};
