import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { Avatar } from 'antd';
import { CrmLogo } from 'images/navigationIcons';
import routePaths from '../../routePaths';
import styles from './index.module.scss';

const PrimaryLogo = ({ className, variant = 'light' }) => (
	<NavLink to={routePaths.homePage} className={cx(styles.root, className)}>
		{variant === 'light' ? (
			<Avatar
				size={{
					xs: 25,
					sm: 25,
					md: 25,
					lg: 40,
					xl: 50,
					xxl: 64,
				}}
				icon={<img src={CrmLogo} alt="CRM for experts" />}
			/>
		) : (
			<img className={styles.logo} alt="CRM - Home" />
		)}
	</NavLink>
);

PrimaryLogo.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.string,
};

export default PrimaryLogo;
