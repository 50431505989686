import moment from 'moment-timezone';

const useTimezoneSelection = () => {
	// Helper function to format timezone into readable string with UTC offset
	const displayTimeZone = () => {
		const timezone = moment.tz.guess();
		const offset = moment.tz(timezone).utcOffset();
		const hours = Math.floor(Math.abs(offset) / 60);
		const minutes = Math.abs(offset) % 60;
		const sign = offset >= 0 ? '+' : '-';
		return `${timezone} (UTC ${sign}${hours.toString().padStart(2, '0')}:${minutes
			.toString()
			.padStart(2, '0')})`;
	};

	return {
		displayTimeZone,
	};
};

export default useTimezoneSelection;
