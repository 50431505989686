import React from 'react';

const PersonAvailable = (props) => (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16.7857 15.8572C18.3242 15.8572 19.5714 17.1044 19.5714 18.6429V19.5714C19.5714 23.2327 16.118 27 10.2857 27C4.45337 27 1 23.2327 1 19.5714V18.6429C1 17.1044 2.24721 15.8572 3.78571 15.8572H16.7857ZM16.7857 17.7143H3.78571C3.27288 17.7143 2.85714 18.13 2.85714 18.6429V19.5714C2.85714 22.2413 5.51686 25.1429 10.2857 25.1429C15.0546 25.1429 17.7143 22.2413 17.7143 19.5714V18.6429C17.7143 18.13 17.2985 17.7143 16.7857 17.7143ZM10.2857 3.78573C13.1063 3.78573 15.3928 6.07227 15.3928 8.89287C15.3928 11.7135 13.1063 14 10.2857 14C7.46511 14 5.17857 11.7135 5.17857 8.89287C5.17857 6.07227 7.46511 3.78573 10.2857 3.78573ZM10.2857 5.64287C8.49078 5.64287 7.03571 7.09794 7.03571 8.89287C7.03571 10.6878 8.49078 12.1429 10.2857 12.1429C12.0806 12.1429 13.5357 10.6878 13.5357 8.89287C13.5357 7.09794 12.0806 5.64287 10.2857 5.64287ZM26.6757 1.22356C27.0218 1.52023 27.0959 2.01637 26.8737 2.39643L26.7764 2.53289L21.205 9.03289C20.8921 9.39794 20.3618 9.45753 19.9792 9.19764L19.8434 9.08518L17.0577 6.29947C16.695 5.93684 16.695 5.3489 17.0577 4.98627C17.38 4.66394 17.8804 4.62812 18.2423 4.87883L18.3709 4.98627L20.4475 7.06294L25.3664 1.32428C25.7001 0.934908 26.2863 0.889815 26.6757 1.22356Z"
			fill="#4F8AFB"
			stroke="#4F8AFB"
			strokeWidth="0.5"
		/>
	</svg>
);

export default PersonAvailable;
