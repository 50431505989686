import {
	createEvent,
	getEvents,
	getAllTypes,
	getEventsByType,
	getExpertsByType,
	getLearnerGroupFiltered,
	getExpertSlots,
	getReasons,
	getOpenWindows,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';
import { convertToLocalTimezone } from '../utils';

export const setMeetingEvents = createAction('SET_MEETING_EVENTS', (data) => ({
	payload: data,
}));

export const setAllEventTypes = createAction('SET_ALL_EVENT_TYPES', (data) => ({
	payload: data,
}));

export const setMeetingEventsByType = createAction(
	'SET_MEETING_EVENTS_BY_TYPE',
	(data) => ({
		payload: data,
	}),
);

export const setMeetingExpertsByType = createAction(
	'SET_MEETING_EXPERTS_BY_TYPE',
	(data) => ({
		payload: data,
	}),
);

export const setFilteredLearnerGroup = createAction(
	'SET_FILTERED_LEARNER_GROUP',
	(data) => ({
		payload: data,
	}),
);

export const setExpertTimeSlots = createAction(
	'SET_EXPERT_TIME_SLOTS',
	(data) => ({
		payload: data,
	}),
);

export const setMeetingMessages = createAction(
	'SET_MEETING_MESSAGES',
	(data) => ({ payload: data }),
);

export const setReasons = createAction('SET_REASONS', (data) => ({
	payload: data,
}));

export const setOpenWindow = createAction('SET_OPEN_WINDOW', (data) => ({
	payload: data,
}));

export const getMeetingEvents = (query) => (dispatch) => {
	getEvents(query)
		.then((response) => {
			dispatch(setMeetingEvents(response));
		})
		.catch((err) => {
			dispatch(setMeetingMessages({ getMeetingsError: err.response }));
		});
};

export const getAllEventTypes = (query) => (dispatch) => {
	getAllTypes(query)
		.then((response) => {
			dispatch(setAllEventTypes(response.data));
		})
		.catch((err) => {
			dispatch(setMeetingMessages({ getAllEventTypesError: err.response }));
		});
};

export const getMeetingEventsByType = (type) => (dispatch) => {
	getEventsByType(type)
		.then((response) => {
			dispatch(setMeetingEventsByType(response.data));
		})
		.catch((err) => {
			dispatch(setMeetingMessages({ getMeetingsByTypeError: err.response }));
		});
};

export const getMeetingExpertsByType = (type) => (dispatch) => {
	getExpertsByType(type)
		.then((response) => {
			dispatch(setMeetingExpertsByType(response.data));
		})
		.catch((err) => {
			dispatch(setMeetingMessages({ getExpertsByTypeError: err.response }));
		});
};

export const getFilteredLearnerGroup = (query) => (dispatch) => {
	getLearnerGroupFiltered(query)
		.then((response) => {
			dispatch(setFilteredLearnerGroup(response.data?.data));
		})
		.catch((err) => {
			dispatch(setMeetingMessages({ getFilteredLearnerGroupError: err.response }));
		});
};

export const getExpertTimeSlots = (query) => (dispatch) => {
	getExpertSlots(query)
		.then((response) => {
			const localTimeMappedSlots = response.data.map((expertSlot) =>
				expertSlot.slots.map((s) => ({
					...s,
					startTime: convertToLocalTimezone(
						`${expertSlot.date} ${s.startTime}`,
					).format('HH:mm:ss'),
					endTime: convertToLocalTimezone(`${expertSlot.date} ${s.endTime}`).format(
						'HH:mm:ss',
					),
				})),
			);
			if (localTimeMappedSlots.length > 0) {
				[response.data[0].slots] = localTimeMappedSlots;
			}
			dispatch(setExpertTimeSlots(response.data));
		})
		.catch((err) => {
			dispatch(setMeetingMessages({ getExpertSlotError: err.response }));
		});
};

export const createMeetingEvent = (payload) => (dispatch) => {
	createEvent(payload)
		.then((res) =>
			dispatch(setMeetingMessages({ createMeetingSuccess: res.data })),
		)
		.catch((err) => {
			dispatch(setMeetingMessages({ createMeetingError: err.response }));
		});
};

export const getAllReasons = (query) => (dispatch) => {
	getReasons(query)
		.then((response) => {
			dispatch(setReasons(response.data));
		})
		.catch((err) => {
			dispatch(setMeetingMessages({ getReasonsError: err.response }));
		});
};

export const getOpenWindowsForExpert = () => (dispatch) => {
	getOpenWindows()
		.then((response) => {
			dispatch(setOpenWindow(response.data[0] || {}));
		})
		.catch((err) => {
			dispatch(setMeetingMessages({ getOpenSlotError: err.response }));
		});
};
