import notificationManagerApis from './axiosInstances/notificationManager';

// Notification Manager APIs
export const getCategoryNotifications = (category) => {
	const url = `/notifications/${category}`;
	return notificationManagerApis.get(url);
};

export const getNotificationUnreadCount = () => {
	const url = `/notifications/count`;
	return notificationManagerApis.get(url);
};

export const getConnectionOtp = () => {
	const url = `/notifications/otp`;
	return notificationManagerApis.get(url);
};

export const setNotificationsSeen = (list) => {
	const url = `/notifications/seen?notifications=${list}`;
	return notificationManagerApis.patch(url);
};
