import {
	createEventOnCalendar,
	getEventsOnCalendar,
	getExpertEventsOnCalendar,
	deleteEvent,
	getEventsOnCalendarUploadHistory,
	getCohorts,
} from 'apis/crm';
import { createAction } from '@reduxjs/toolkit';

export const setCohorts = createAction('SET_COHORTS', (data) => ({
	payload: data,
}));

export const setCalendarEvents = createAction(
	'SET_CALENDAR_EVENTS',
	(data) => ({
		payload: data,
	}),
);

export const setCalendarEventPosition = createAction(
	'SET_CALENDAR_EVENT_POSITION',
	(data) => ({
		payload: data,
	}),
);

export const setCalendarEventsHistory = createAction(
	'SET_CALENDAR_EVENTS_HISTORY',
	(data) => ({
		payload: data,
	}),
);

export const setCalendarMessages = createAction(
	'SET_CALENDAR_MESSAGES',
	(data) => ({ payload: data }),
);

export const getCrmCohorts = () => (dispatch) => {
	getCohorts().then((response) => {
		dispatch(setCohorts(response.data));
	});
};

export const getCalendarEvents = (query) => (dispatch) => {
	getEventsOnCalendar(query)
		.then((response) => {
			dispatch(setCalendarEvents(response));
		})
		.catch((err) => {
			dispatch(setCalendarMessages({ getEventsError: err.response }));
		});
};

export const getCalendarEventsByExpert = (query) => (dispatch) => {
	getExpertEventsOnCalendar(query)
		.then((response) => {
			dispatch(setCalendarEvents(response));
		})
		.catch((err) => {
			dispatch(setCalendarMessages({ getEventsError: err.response }));
		});
};

export const getCalendarEventsUploadHistory = (query) => (dispatch) => {
	getEventsOnCalendarUploadHistory(query)
		.then((response) => {
			dispatch(setCalendarEventsHistory(response));
		})
		.catch((err) => {
			dispatch(setCalendarMessages({ getHistoryEventsError: err.response }));
		});
};

export const createCalendarEvent = (payload) => (dispatch) => {
	createEventOnCalendar(payload)
		.then((res) =>
			dispatch(setCalendarMessages({ createEventSuccess: res.data })),
		)
		.catch((err) => {
			dispatch(setCalendarMessages({ createEventError: err.response }));
		});
};

export const deleteCalMeeting = (payload) => (dispatch) => {
	deleteEvent({ ...payload })
		.then((res) =>
			dispatch(
				setCalendarMessages({ deleteEventSuccess: res.data, recordId: payload.id }),
			),
		)
		.catch((err) => {
			dispatch(setCalendarMessages({ deleteEventError: err.response }));
		});
};
