import platform from 'platform';
import rollbar from 'utils/rollbar';
import { constants } from 'utils/constants';
import { getCookie } from 'utils/cookies';
import { store } from 'store';
import { BUTTON_CLICK, MODAL_VIEW } from './constants';
export function isClevertapAvailable() {
	return typeof window.clevertap !== 'undefined';
}

const os = platform.os || {};

const baseProperties = {
	client_os: os.family,
	client_version: os.version,
	user_agent: platform.ua,
	courseid: '',
	width: `${window.innerWidth}px`,
	height: `${window.innerHeight}px`,
	devicetype:
		constants('desktopBreakPoint') >= window.innerWidth ? 'mobile' : 'web',
	platform_version: platform.version,
	browser: platform.name,
	manfacturer: platform.manufacturer,
	device_model: platform.description,
	userid: '',
	course_name: '',
	course_category: '',
	page_title: '',
	app_session: getCookie(`upgrad-sessionId.${constants('env')}`),
	version: 'lite',
	device_user_id: '',
};

export function initialize({ CLEVERTAP_ID }) {
	if (!CLEVERTAP_ID) return;
	window.clevertap = {
		event: [],
		profile: [],
		account: [],
		onUserLogin: [],
		notifications: [],
	};

	window.clevertap.account.push({ id: CLEVERTAP_ID });
	(() => {
		const wzrk = document.createElement('script');
		wzrk.type = 'text/javascript';
		wzrk.async = true;
		wzrk.src = `${
			document.location.protocol === 'https:'
				? 'https://d2r1yp2w7bby2u.cloudfront.net'
				: 'http://static.clevertap.com'
		}/js/a.js`;
		const s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(wzrk, s);
	})();
}

const clean = (obj) => {
	const value = { ...obj };
	Object.keys(value).forEach((key) => {
		if (value[key] === null || value[key] === '' || value[key] === undefined) {
			delete value[key];
		}
	});

	return value;
};

export const LogProfileEvent = (eventName, properties = {}) => {
	const { profile } = store.getState();
	const { customBaseproperties } = profile;
	LogEvent(eventName, {
		...properties,
		...customBaseproperties,
	});
};

export function LogEvent(name, properties = {}) {
	const { user } = store.getState();
	let values = properties;
	if (!isClevertapAvailable()) return;
	if (!name) return;
	try {
		if (!properties || !typeof properties === 'object') {
			values = {};
		}
		values = {
			...clean({
				...baseProperties,
				userid: user.id,
				email: user.email,
				page_url: window.location.href,
				device_user_id: user.id,
				Time_stamp: new Date(),
			}),
			...properties,
		};
		window.clevertap.event.push(name, values);
	} catch (e) {
		rollbar.error(`Unable to track event ${name}`, e);
	}
}

export function setUser({ name, email }) {
	if (!isClevertapAvailable()) return;
	window.clevertap.profile.push({
		Site: {
			Name: name,
			Email: email,
		},
	});
}

class EventLogger {
	LogButtonClickEvent = (
		cleverTapProperties,
		modalName,
		buttonType,
		buttonName,
	) => {
		LogEvent(BUTTON_CLICK, {
			...cleverTapProperties,
			modal_name: modalName,
			button_type: buttonType,
			button_name: buttonName,
		});
	};

	LogModalViewEvent = (cleverTapProperties, modalName) => {
		LogEvent(MODAL_VIEW, {
			...cleverTapProperties,
			modal_name: modalName,
		});
	};
}

export const EventLoggerObj = new EventLogger();
