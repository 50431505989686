/* eslint-disable */
import { createReducer } from '@reduxjs/toolkit';
import {
	setRequests,
	setRequestCounts,
	setRequestMessages,
} from '../actions/requests';

const initialState = {
	requests: { data: [], headers: {} },
	counts: {},
	messages: {
		recordId: null,
		getRequestError: {},
		getRequestCountError: {},
	},
};

const requestsReducer = createReducer(initialState, {
	[setRequests]: (state, action) => {
		state.requests = action.payload;
	},
	[setRequestCounts]: (state, action) => {
		state.counts = action.payload;
	},
	[setRequestMessages]: (state, action) => {
		// if (action.payload && action.payload.recordId) {
		// 	state.requests.data = state.requests.data.filter(
		// 		(item) => item.id !== action.payload.recordId,
		// 	);
		// }
		state.messages = { ...state.messages, ...action.payload };
	},
});

export default requestsReducer;
